import React, { useEffect, useState } from 'react'
import './AdvertiserDashboardCampaigns.css';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { getToken } from '../../../../features/token/tokenSaver';
import { getAdvertiserData } from '../../../../services/getAdvertiserData';
import { getAdvertiserCampaigns } from '../../../../services/getAdvertiserCampaigns';

export default function AdvertiserDashboardCampaigns(props) {
    const navigate = useNavigate();
    var abbreviate = require('number-abbreviate')

    const [campaigns, setCampaigns] = useState([]);
    const token = useSelector(getToken)
    function parsed() {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    }
    useEffect(() => {
        const decoded = parsed();
        if (!decoded) return;
        getAdvertiserCampaigns(token, decoded.id).then(response => {
            if (response.data.data) {
                setCampaigns(response.data.data);
            }
        })
        return () => {

        }
    }, [token])

    return (

        <div className='advertiserDashboardContainerCampaigns'>
            <div className='adminDashboardScreenPageContainer'>
            <span className='registeredScreensTitle advertiserListTitle'>My Campaigns</span>
                <input type={"submit"} value={"+"} className='addAdvertiser' onClick={() => {
                    navigate("/advertiserDashboard/newCampaign")
                }} />

            <div className='adminDashboardScreensTableContainer'>
                {campaigns.length > 0 && <table className='adminDashboardScreensTable'>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Ad Name</th>
                            <th>Status</th>
                            <th>Impressions Remaining</th>
                            <th>Budget</th>
                        </tr>
                    </thead>
                    <tbody>
                        {campaigns.map(campaign => (
                            <tr onClick={() => { navigate("/advertiserDashboard/campaignDetails", { state: { adId: campaign.AdvertisementId } })}} key={campaign.AdvertisementId}>
                                <td>{campaign.AdvertisementId}</td>
                                <td>{campaign.AdvertisementName}</td>
                                <td>{['Enabled', 'Disabled', 'Pending'][campaign.StatusId-1]}</td>
                                <td>{campaign.ImpressionsRemaining}</td>
                                <td>${abbreviate(campaign.CurrentBudget,2)}</td>
                                {/* <td><span className={advertiser.Active === "true" && 'activeStatus' || 'inactiveStatus'}>{advertiser.Active === "true" && 'Active' || 'Inactive'}</span></td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>}
                </div>
            </div>
        </div>
    )
}
