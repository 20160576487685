import React, {useMemo, useEffect, useState} from 'react'
import "./AdminDashboardScreensTable.css"
import { getScreenData } from '../../../../services/getScreenData';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { getToken } from '../../../../features/token/tokenSaver';
export default function AdminDashboardScreensTable(props) {
    const [page, setPage] = useState(0);
    const [screenData, setScreenData] = useState([]);
    const [forwardDisabled, setForwardDisabled] = useState(false);
    const [max, setMax] = useState(1);
    const navigate = useNavigate();
    const token = useSelector(getToken)

    useEffect(() => {
            populateTable(true);

      return () => {
        
      }
    }, [])
    function populateTable(forward, fromSearch) {
        setScreenData([]);

        getScreenData(token, (forward && page+1 || page-1), 10).then(response => {
            setScreenData(response.data.screens);
            if(forward){
            setPage(page + 1);}
            else {
                setPage(page - 1);
            }
            setMax(Math.ceil(response.data.count / 10))
            if (Math.ceil(response.data.count / 10) <= (forward && page + 1 || page - 1)) {
                setForwardDisabled(true)
            }
            else {
                setForwardDisabled(false)

            }
        }).catch(e => {
            console.log(e)
        });

    }


    return (
        <div className='adminDashboardScreensTableContainer'>
            {props.data && <table className='adminDashboardScreensTable'>
                <thead>
                    <tr>
                        <th>Screen Id</th>
                        <th>Owner Id</th>
                        <th>Active</th>
                        <th>Last Active</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map(screen => {
                        return <tr key={screen.ScreenId} onClick={() => {
                            props.screenDetails(screen.ScreenId);

                        }}>
                            <td>{screen.ScreenId}</td>
                            <td>{screen.OwnerUserId}</td>
                            <td ><span className={screen.Active === "true" && 'activeStatus' || 'inactiveStatus'}>{screen.Active === "true" && 'Active' || 'Inactive'}</span></td>
                            <td>{new Date(screen.LastActive).toLocaleDateString()}</td>
                        </tr>
                    })}
                </tbody>
            </table>}
            {!props.data && <table className='adminDashboardScreensTable'>
            <thead>
                <tr>
                    <th>Screen Id</th>
                    <th>Owner Id</th>
                    <th>Active</th>
                    <th>Last Active</th>
               </tr>
            </thead>
            <tbody>
                   { screenData.map(screen => {
                       return <tr key={screen.ScreenId} onClick={() => {
                           props.screenDetails(screen.ScreenId);

                       }}>
                           <td>{screen.ScreenId}</td>
                           <td>{screen.OwnerUserId}</td>
                          <td ><span className={screen.Active === "true" && 'activeStatus' || 'inactiveStatus'}>{screen.Active === "true" && 'Active' || 'Inactive'}</span></td>
                           <td>{new Date(screen.LastActive).toLocaleDateString()}</td>
                       </tr>
                })}
            </tbody>
            </table>}
            <div className='tablePaginationContainer'>
                <span className='paginationIcon' onClick={() => {
                   
                    populateTable(false);
                }} disabled={page <= 1}>{'<'}</span>
                <input className='page' max={max} min={1} type={"number"} onChange={async (e) => {
                  await  setPage(e.nativeEvent.target.value);
                    populateTable();
                }} placeholder={page}/>
                <span className='paginationIcon' onClick={() => {
                    populateTable(true);
                }} disabled={forwardDisabled}>{'>'}</span>
            </div>
        </div>    )
}
