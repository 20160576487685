import axios from "axios"
export const sendTextMessage = (phoneNumber, code) => {
    axios.post(process.env.REACT_APP_API_URL+ '/sendMessage', {
        to: phoneNumber,
        body: `Welcome to INVERT! Here's your code: ${code}`,
        code: code
    })
        .then(function (response) {
        })
        .catch(function (error) {
            console.log(error);
        });
}