import { LineChart, Line, CartesianGrid, YAxis, XAxis, Tooltip } from 'recharts';
import './AdvertiserCampaignDetailsLineGraph.css';
import React, {useEffect, useMemo, useState} from 'react';
import { useSelector } from 'react-redux';

export default function AdvertiserCampaignDetailsLineGraph({passedData}) {
const [data, setData]= useState([])
    function convertToDate(date) {
        var formateddate = new Date(date);
        formateddate = formateddate.toDateString();
        return formateddate;
   }
    function getCurrentTimezone() {
        // Get the current date and time in the local timezone
        const currentDate = new Date();

        // Get the timezone from the Intl.DateTimeFormat object
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return timezone;
    }

    useEffect(() => {
        var formattedGraphData = [];
        if (passedData) {
            passedData.forEach(item => {
                formattedGraphData.push({ name: convertToDate(item.Date), Impressions: item.Count, pv: 2400, amt: 2400 })

            })
            setData(formattedGraphData);
        }
      }, [passedData])
    
     
    
    return (
        <div className='graphContainerDetails'>
            <span className='graphTitle'>Impressions Throughout Lifetime</span>
            <span className='graphSubTitle'>Current Timezone: {getCurrentTimezone()}</span>
        <LineChart width={480} height={180} data={data}>
            <Line type="monotone" dataKey="Impressions" stroke="#F6B5B5" />
                <CartesianGrid stroke="#ffff" />
                <XAxis dataKey="name" />
            <Tooltip />
            </LineChart>
            
        </div>
    )
}
