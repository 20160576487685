import React,{useEffect, useState} from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { getUserDashboardData } from '../../../services/getUserDashboardData';
import './UserDashboard.css'
import UserNav from '../UserNav/UserNav';
export default function UserDashboard() {
    const { state } = useLocation();
    const navigate = useNavigate();
    var abbreviate = require('number-abbreviate')

    const [screensData, setScreensData] = useState([])
    const [screensActivity, setScreensActivity] = useState([])
    const token = secureLocalStorage.getItem("tk");
    function convertGMTToLocal(gmtTime) {
        const date = new Date(gmtTime);
        var localTime = date.toLocaleString();
        return localTime;
    }
    useEffect(() => {
        if (!state) {
            navigate("/");
        }
        else if (!state?.userId || state?.userId == "") {
            navigate("/")
        }
       
    
    }, [])
    
   

  return (
      <div>
          <UserNav></UserNav>
          <Outlet/>
         </div>
          )
}
