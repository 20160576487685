import React, { useEffect, useState } from 'react'
import './AdminDashboardAdvertisers.css';
import { getAdvertiserData } from '../../../services/getAdvertiserData';
import { Outlet, useOutletContext } from 'react-router-dom';

export default function AdminDashboardAdvertisers() {

  
  return (

    <div className='adminDashboardContainer' className='adminDashboardContainer'>
      <div className='adminDashboardScreenPageContainer'>
      <Outlet  />
      </div>
    </div>
  )
}
