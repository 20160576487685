import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom';
import adminBg from '../../../assets/images/loginBG.png';
import { checkEmptyObj } from '../../../services/checkEmptyObj';
import './AdminLogin.css';
import { adminLogin } from '../../../services/adminLogin';
import { useDispatch } from 'react-redux'
import { save } from '../../../features/token/tokenSaver';
export default function AdminLogin() {
    const [phoneNumber, setPhoneNumber] = useState();
    const [adminLoginData, setadminLoginData] = useState({ username: "", password: "" });
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    function onChange(propertyName, newValue) {
        setadminLoginData({ ...adminLoginData, [propertyName]: newValue });
    }
    function onSubmit(event) {
        event.preventDefault();
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


        if (checkEmptyObj(adminLoginData) && !adminLoginData.username.match(validRegex)) {
            setError(true);
            return;
        }
        else {
            adminLogin(adminLoginData).then(result => {
                if (result.status != 200) {
                    setError(true)
                    return;
                }
                setError(false);
                dispatch(save(result.data.token));
                localStorage.setItem("refreshToken", result.data.refreshToken);
                navigate("/adminDashboard", { state: {token: result.data.token, name: result.data.fname + ' '+ result.data.lname} })

                return;
                }).catch(e => {
                    setError(true)
                })

        }
    }
  return (
      <div className='adminLoginContainer'>
          <div className='loginContainer'>
              <form onSubmit={onSubmit}>
              <div className='loginSubContainer'> 
              <span className='adminLoginTitle'>Admin Login</span>
                      <span className='adminLoginDescription'>Login with your email and password</span>
<div className='adminLoginInputs'>
                  <input type={"email"} value={adminLoginData.username} onChange={(e) => { onChange("username", e.nativeEvent.target.value) }} required placeholder='Email' className='adminLoginPhoneInput' />
                  <input type={"password"} required value={adminLoginData.password} onChange={(e) => { onChange("password", e.nativeEvent.target.value) }} placeholder='Password' className='adminLoginPhoneInput' />
                      {error && <span className='errorPhoneVerification'>Invalid email or password</span>}
                      <div className='adminLoginUnderItemsContainer'>
                          <input type={"submit"} value={"Login"} className='adminLoginBtn' />
                              <span className='adminLoginUnderText' onClick={() => { navigate('/ContactUs') }}>Want to advertise? <u>Contact Us</u></span>
                          </div>
                      </div>
              </div>
              
              </form>
              </div>
              
          <img className='loginBG' src={adminBg}/>
    </div>
  )
}
