import React, {useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getCampaignDetails } from '../../../../../services/getCampaignDetails';
import { useSelector } from 'react-redux'
import { getToken } from '../../../../../features/token/tokenSaver';
import './AdvertiserCampaignDetails.css'
import { toggleAdvertismentAdvertiser } from '../../../../../services/toggleAdvertismentActiveAdvertiser';
import ToggleButton from 'react-toggle-button'
import { deleteAdvertisementCampaign } from '../../../../../services/deleteAdvertismentCampaign';
import AdvertiserCampaignDetailsLineGraph from './LineGraph/AdvetiserCampaignDetailsLineGraph';
import { toggleAdvertisment } from '../../../../../services/toggleAdvertisement';
import { deleteAdvertisementCampaignAdmin } from '../../../../../services/deleteAdvertismentCampaignAdmin';
import { getCampaignDetailsAdmin } from '../../../../../services/getCampaignDetailsAdmin';
import leftChevron from '../../../../../assets/images/leftChevron.png';
import { approveApplication } from '../../../../../services/approveApplication';
import { RingLoader } from 'react-spinners';
import { approveAdvertisement } from '../../../../../services/approveAdvertisement';

export default function AdminDashboardAdvertiserCampaignDetails() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const token = useSelector(getToken)
    const [adData, setAdData] = useState({});
    const [adLogData, setAdLogData] = useState([]);
    const [locations, setLocations] = useState([]);
    const [percentage, setPercentage] = useState(0);
    const [loading, setLoading] = useState(false);
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const [adDataDesiredTime, setAdDataDesiredTime] = useState('');
    function loadCampaignDetails() {
        getCampaignDetailsAdmin(token, state?.adId).then(response => {
            setAdData(response.data?.advertismentData);
            setAdLogData(response.data?.adLogData);
            setLocations(response.data?.locations);
            setAdDataDesiredTime(convertToFormattedLocalTime(response.data?.advertismentData.DesiredTime));
            setPercentage(response.data?.percentage.Percentage);
        }) 
    }
    useEffect(() => {
       
        loadCampaignDetails();

    }, []);
    function convertToFormattedLocalTime(datetimeString) {
        const inputDate = new Date(datetimeString);
        const localDate = new Date(inputDate.getTime() + (inputDate.getTimezoneOffset() * 60000));

        const hours = String(localDate.getHours() % 12 || 12).padStart(2, '0');
        const minutes = String(localDate.getMinutes()).padStart(2, '0');
        const ampm = localDate.getHours() >= 12 ? 'PM' : 'AM';

        const timezoneOffsetHours = Math.abs(localDate.getTimezoneOffset() / 60);
        const timezoneOffsetMinutes = Math.abs(localDate.getTimezoneOffset() % 60);
        const timezone = (localDate.getTimezoneOffset() < 0 ? '+' : '-') +
            String(timezoneOffsetHours).padStart(2, '0') +
            ':' +
            String(timezoneOffsetMinutes).padStart(2, '0');

        return `${hours}:${minutes} ${ampm} (GMT${timezone})`;
    }

    if (!state?.adId) {
    navigate(-1)
    }
    function toggleAdvertisementStatus(newStatus) {
        let formatted = '';
        if (newStatus) {
            formatted = '1'
        }
        else {
            formatted = '2'
        }
        toggleAdvertisment(token, adData?.AdvertisementId, formatted).then(response => {
            loadCampaignDetails();

        })
    }
    function deleteAdvertisement() {
        setLoading(true)
        var confirmed = window.confirm(`Delete Advertisment #${adData.AdvertisementId}?`);
        if (!confirmed) {
            return;
        }
        deleteAdvertisementCampaignAdmin(token, adData.AdvertisementId).then(response => {
            navigate('/advertiserDashboard/campaigns');
        })
    }
    function approveAdvertisementCall() {
        setLoading(true)
        approveAdvertisement(adData.AdvertisementId, token).then(reponse => {
            alert("Advertisement Approved!");
            loadCampaignDetails();
            setLoading(false)
        })
    }
  return (
      <div >
          <img className='leftChevronAdvertiserDetails' onClick={() => { navigate(-1) }} src={leftChevron} />

              <span className='advertismentCampaignDetailsTitle'>{adData.AdvertisementName}- #{adData.AdvertisementId}</span>
              <div className='advertismentDetailsContentAndImpressionsContainer'>
              <div className='advertismentCampaignDetailsHalfContainer'>
                  <span className='advertismentCampaignDetailsSubTitle'>Content- {(adData.Storefront && "Storefront") || "Checkout"} Ad</span>
                  <img className='advertismentImage' src={adData.AdContentHorizontal} />
                          <img className='advertismentImage' src={adData.AdContentVertical} />
              </div>
              <div className='advertismentCampaignDetailsHalfContainer'>
                  <span className='advertismentCampaignDetailsSubTitle'>This Month's Impressions</span>
                      <AdvertiserCampaignDetailsLineGraph passedData={adLogData}></AdvertiserCampaignDetailsLineGraph>
                  </div>
              </div>
        
              <div className='advertismentCampaignDetailsFullContainer'>
                  <span className='advertismentCampaignDetailsSubTitle'>Stats</span>
                  <div className='advertismentStatsInnerContainer'>
                  <span className='advertismentCampaignDataTitle'>Desired Viewing Time: {adDataDesiredTime}</span>
                      <span className='advertismentCampaignDataTitle'>Desired Location(s): {locations.map(location => { return (<span className='advertiserCampaignDetailsLocationsContainers'>{location.City + ', ' + location.State}</span>) })}</span>
                      <span className='advertismentCampaignDataTitle'>Percentage of Impressions Within 30 Mins of Desired Time: {percentage.toFixed(1)}%</span>
                  </div>
              </div>
              <div className='advertismentCampaignDetailsFullContainer'>
                  <span className='advertismentCampaignDetailsSubTitle'>Pricing</span>
                  <div className='advertismentStatsInnerContainer'>
                      <span className='advertismentCampaignDataTitle'>Cost Per Impression: {formatter.format(adData.CostPerImpression)}</span>
                      <span className='advertismentCampaignDataTitle'>Budget: {formatter.format(adData.CurrentBudget)}</span>
                      <span className='advertismentCampaignDataTitle'>Impressions Purchased: {adData.Impressions}</span>
                      <span className='advertismentCampaignDataTitle'>Impressions Remaining: {adData.ImpressionsRemaining}</span>
                  </div>
              </div>
              <div className='advertismentCampaignDetailsFullContainer'>
                  <span className='advertismentCampaignDetailsSubTitle'>Take Action</span>
                  <div className='advertismentStatsInnerContainer'>
                      <span className='advertismentCampaignDataTitle'>Ad Status: {["Enabled", "Disabled", "Pending"][adData.StatusId - 1]} {adData.StatusId != 3 &&
                          <ToggleButton className="toggleBtn"
                          value={adData.StatusId == 1}
                          onToggle={(value) => {
                              toggleAdvertisementStatus(!value);
                          }} />}</span>

              </div>
              <button onClick={() => { approveAdvertisementCall() }} className='approveBtn userDetailsBtn'>
                  {loading && <RingLoader
                      color={"#ffffff"}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      className='loader'
                  />}{!loading && "Approve Advertisement"}</button>
                  <button className='denyBtnCampaignDetails userDetailsBtn' onClick={()=>{deleteAdvertisement()}}> 
                  {loading && <RingLoader
                      color={"#ffffff"}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      className='loader'
                  />}{!loading && "Delete Advertisement"}
              
              </button>     
           
              </div>
              </div>
  )
}
