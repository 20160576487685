import { LineChart, Line, CartesianGrid, YAxis, XAxis, Tooltip } from 'recharts';
import './DashboardLineGraph.css';
import React, {useEffect, useMemo, useState} from 'react';
import { getAdLogsGraph } from '../../../../../services/getAdLogsGraph';
import { getToken } from '../../../../../features/token/tokenSaver';
import { useSelector } from 'react-redux';

export default function DashboardLineGraph() {
    const token = useSelector(getToken);

    const [data, setData] = useState([]);
    function convertGMTToCurrentTime(gmtTime) {
        // Split the HH:MM time into hours and minutes
        const [hours, minutes] = gmtTime.split(':').map(Number);

        // Get the current date and time in the local timezone
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();

        // Create a new Date object with the same date but the provided GMT hours and minutes
        const gmtDate = new Date(Date.UTC(currentYear, currentMonth, currentDay, hours, minutes));

        // Return the converted time in HH:MM format
        return gmtDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });
    }
    function getCurrentTimezone() {
        // Get the current date and time in the local timezone
        const currentDate = new Date();

        // Get the timezone from the Intl.DateTimeFormat object
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return timezone;
    }

    useEffect(() => {
        
        function parsed() {
            try {
                return JSON.parse(atob(token.split('.')[1]));
            } catch (e) {
                return null;
            }
        }
        const decoded = parsed();
        if (decoded) {

            getAdLogsGraph(token, decoded.id).then(response => {
                const data = response.data.data;
                let formattedGraphData = []
                data.forEach(item => {

                    
                    // item.time.split(":")[0] + o
                    formattedGraphData.push({ name: convertGMTToCurrentTime(item.time), Impressions: item.rows_per_interval, pv: 2400, amt: 2400 })
                });
                setData(formattedGraphData);
            });
        }
      }, [token])
    
     
    
    return (
        <div className='graphContainer'>
            <span className='graphTitle'>Impressions Throughout The Day</span>
            <span className='graphSubTitle'>Current Timezone: {getCurrentTimezone()}</span>
        <LineChart width={750} height={250} data={data}>
            <Line type="monotone" dataKey="Impressions" stroke="#F6B5B5" />
                <CartesianGrid stroke="#ffff" />
                <XAxis dataKey="name" />
            <Tooltip />
            </LineChart>
            
        </div>
    )
}
