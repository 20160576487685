import React, { useEffect } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom';
import './AdminDashboardScreens.css';

export default function AdminDashboardScreens(props) {

  useEffect(() => {
    return () => {
      
    }
  }, [])
  
  return (

    <div className='adminDashboardContainer'>
      <div className="adminDashboardScreenPageContainer">
        <Outlet />
      </div>
    </div>
  )
}
