import React, { useState, useEffect } from 'react'
import './Login.css';
import RingLoader from "react-spinners/RingLoader";

import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input';
import { userExists } from '../../../services/userExists';
import { isValidPhoneNumber } from 'react-phone-number-input';
export default function Login() {
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem("userData")));
    const [veriCode, setVeriCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState(false);
    const [code, setCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState();
    const [loading, setLoading] = useState(false);

    const { state } = useLocation();

    const navigate = useNavigate();


    useEffect(() => {

    
                // setUserData(state);
                // console.log(state);
                // var vcode = ""
                // for (var i = 1; i <= 6; i++) {
                //     vcode += Math.floor(Math.random() * 10).toString();

                // }
                // setCode(vcode);
                // console.log(vcode);
                // sendTextMessage(state.phoneNumber, vcode);

            // }
            // else {
            //     navigate("/")
            // }
       
    }, [])
  async  function onSubmit(event) {
        event.preventDefault();
        setLoading(true);
        if (isValidPhoneNumber(phoneNumber)) {
            await userExists(phoneNumber.replace('+1', '')).then((response) => {
                if (response.data.userExists) {
                    setLoading(false);
                    setError(false);

                    navigate("/userVerification", { state: { user: {phoneNumber: phoneNumber}, toRegister: false } })
                }
                else {
                    setLoading(false);
                    
                    navigate("/", { state: { phoneNumber: phoneNumber} })

                }
           })
           
       }
        else {
            setLoading(false);

            setError(true);
            setErrorMessage("Please enter a valid phone number.")
       }
    }
    return (
        <div className='verificationPage'>
            <div className='verificationContainer'>
                <div className='verificationBox loginBox'>
                    <form onSubmit={onSubmit}>
                        <span className='verificationBoxLogo'>INVERT</span>
                        <span className='verificationBoxTitle'>Welcome back!</span>
                        <span className='verificationBoxSubTitle'>We'll send a text to verify your number.</span>
                        <PhoneInput
                            className='loginphoneNumberInput'
                            country="US" required
                            defaultCountry="US"
                            national={"false"}
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            placeholder='Mobile Phone Number' />
                        {error && <span className='errorPhoneVerification'>{errorMessage}</span>}
                        <button className="verificationBtn" type='submit' value={"Save"} >
                        {!loading && "Next"}
                        {loading && <span className='loginRingLoaderContainer'><RingLoader
                            color={"#ffffff"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        /></span>}
                    </button>
                    </form>
                </div>
            </div>

        </div>
    )
}
