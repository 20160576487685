import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/invertLogo.png';
import video from '../../assets/images/timesSquareVid.mp4';
import './Landing.css';
import secureLocalStorage from 'react-secure-storage';
import { checkToken } from '../../services/checkToken';
import { refreshJWT } from '../../services/refreshJWT';

export default function Landing() {
    const navigate = useNavigate();
    function scrollIntoView() {
        var moreInfoElement = document.getElementById("moreInfo");
        moreInfoElement.scrollIntoView();
    }
    useEffect((() => {
        var possibleToken = secureLocalStorage.getItem("tk");
        var refreshToken = secureLocalStorage.getItem("refreshToken");
        const parsedJwt = () => {
            try {
                return JSON.parse(atob(possibleToken.split('.')[1]));
            } catch (e) {
                return null;
            }
        };
        if (parsedJwt) {
            const phoneNumber = parsedJwt()?.phoneNumber.replace('+1', '');
        
            checkToken(possibleToken).then(response => {
                if (response.data?.level == "user") {
                    navigate("user/todo/", { state: { phoneNumber: phoneNumber, token: possibleToken } });
                }
            }).catch(e => {
                if (e.response?.data?.error === "EXPTOKEN" && refreshToken && phoneNumber) {
               
                    refreshJWT(refreshToken, phoneNumber).then(response => {
                        secureLocalStorage.setItem("tk", response.data.token);
                        secureLocalStorage.setItem("refreshToken", response.data.newRefreshToken);
                        navigate("user/todo/", { state: { phoneNumber: phoneNumber, token: response.data.token } });
                    }).catch(e => {
                        console.log("error with refreshing token")
                    })
                }
            })
        }
    }), [])
  return (
      <>
          <nav className='landingPageNav'>
              <img src={logo} />
              <div className='landingPageNavMottoContainer'>
                  <span className='landingPageNavMotto'>Elevate Ad Space.<br/> Maximize Impact.</span>
              </div>
              <span className='landingPageNavButton' onClick={() => { navigate('/userHome')}}>Get Started</span>

              <span className='landingPageNavButton' onClick={() => { navigate('/contactUs') }}>Contact Us</span>

          </nav> 
          <div className='landingPageFirstContainer'>
              <div className='landingPageVideo'>
              <video className='landingPageVideo' muted autoPlay loop>
                  <source src={video} type="video/mp4" />

                  </video></div>
          <span className='landingPageCenterMessage'>
              Monetize Your Space.<br/>
              Amplify Your Brand. 
          </span>
          <span className='landingPageCenterSubMessage'>
              Transform Your Business Space into a Revenue-Generating Advertising Platform with<br/> INVERT's Innovative Screen Network.
          </span>
          <div className='opportunitiesButtonsContainer'>
                  <div className='opportunitiesButtonContainer'>  <span className='opportunitiesButtonPartner' onClick={() => { navigate('/userHome') }}>Become a Partner</span>
                  </div><div className='opportunitiesButtonContainer'><span className='opportunitiesButtonLearnMore' onClick={() => { scrollIntoView() }}>Learn More</span>
                  </div>
              </div>
          </div>
          <div className='moreInfo1' id='moreInfo'>
              <span className='moreInfoTitle'>
                  Unlock the Potential of Your Business <br/> Space with INVERT
              </span>
              <span className='moreInfoText'>
                  Welcome to INVERT, where we're redefining the way businesses connect with their audience. Our<br/> innovative platform empowers small businesses to monetize their physical space by hosting digital<br/> screens that display targeted advertisements.
                 

              </span>
              <div className='gettingStartedButtonContainer'> <span className='moreInfoButtonGetStarted' onClick={() => { navigate('/userHome') }}>Get Started</span></div>

          </div>
          <div className='moreInfo2'>
              <span className='moreInfoTitle'>
Why Partner With INVERT?              </span>
              <span className='moreInfoText'>
                  <ul className='moreInfoList'>
                  <li>Generate Additional Revenue: Turn your business location into a passive income stream. Earn every time an advertisement is viewed on your screen.
                      </li><li>Enhance Customer Experience: Engage your customers with dynamic and relevant advertising content. Elevate their experience while they interact with your brand.
                      </li><li> No Upfront Costs: There are no initial expenses to host a screen. We provide the hardware, and you start earning from day one.
                      </li><li> Flexible Control: You have full control over when and where the advertisements are displayed. Activate, deactivate, or delete campaigns at your convenience.
                      </li> </ul>
              </span>
              <div className='gettingStartedButtonContainer'> <span className='moreInfoButtonGetStarted' onClick={() => { navigate('/userHome') }}>Get Started</span></div>

          </div>
    </>
  )
}
