import React, { useEffect, useState } from 'react'
import './AdminDashboardScreenDetails.css';
import screenProfile from '../../../../assets/images/screenProfile.png';
import leftChevron from '../../../../assets/images/leftChevron.png';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { getToken } from '../../../../features/token/tokenSaver';
import { getScreenDetails } from '../../../../services/getScreenDetails';
export default function AdminDashboardScreenDetails(props) {
    const token = useSelector(getToken)

    const [screenDetails, setScreenDetails] = useState({});
    useEffect(() => {
        getScreenDetails(token, props.screenId).then(response => {
            setScreenDetails(response.data.screen);
        })
        return () => {
            
        }
    }, [])
  
    return (
        <div >
            <img className='leftChevronScreenDetails'onClick={()=>{props.screensTable()}} src={leftChevron}/>
            <div className='screenDetailsScreenImgContainer'>
                <img className='screenPfpImg' src={screenProfile} />
            </div>
            <div className='screenDetailsScreenDetailsContainer'>

                <span className='screenDetailsScreenId'>#{screenDetails.ScreenId}</span>
                <div className='screenDetailsScreenDetailsSubContainer'>
                    
                    <span className='screenDetailsItem'>Total Ad Hours: {Math.round((parseInt(screenDetails.TotalShownSeconds) / 60) * 100) / 100}</span>
                    <span className='screenDetailsItem'>Last Active: {screenDetails.LastActive}</span>
                    <span className='screenDetailsItem'>Last IP: {screenDetails.LastIP}</span>
                    <span className='screenDetailsItem'>Status: {screenDetails.Active === 'true' && 'Active' || 'Inactive'}</span>
                    <span className='screenDetailsItem'>Owner Id: {screenDetails.OwnerUserId}</span>
                </div>
            </div>
            <span className='screenDetailsScreenId curAdGal'>Current Ad Gallery</span>

            </div>
    )
}
