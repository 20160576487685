import { createSlice } from '@reduxjs/toolkit'

export const tokenSaverSlice = createSlice({
    name: 'token',
    initialState: {
        value: ""
    },
    reducers: {
        save: (state, action) => {
            state.value = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const { save } = tokenSaverSlice.actions
export const getToken = (state) => state.token.value

export default tokenSaverSlice.reducer