import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './app/store'
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <PayPalScriptProvider
    options={{
      "clientId": process.env.REACT_APP_PAYPAL_CLIENT_ID,
      components: "buttons",
      currency: "USD"
    }}
  >
      <BrowserRouter >

  <Provider store={store}>
   
    <App />
    </Provider>
    </BrowserRouter>
  </PayPalScriptProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
