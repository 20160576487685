import React, { useEffect, useState } from 'react'
import '../AdminDashboardAdvertisers.css';
import { getAdvertiserData } from '../../../../services/getAdvertiserData';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { getToken } from '../../../../features/token/tokenSaver';
export default function AdvertisersHome(props) {
    const navigate = useNavigate();
    const [advertisers, setAdvertisers] = useState([]);
    const token = useSelector(getToken)

    useEffect(() => {
        getAdvertiserData(token).then(response => {
            setAdvertisers(response.data.advertisers)
        }).catch(e => {
            console.log(e.data)
        })
        return () => {

        }
    }, [])

    return (

            <div >
                <span className='registeredScreensTitle advertiserListTitle'>Registered Advertisers</span>
            <input type={"submit"} value={"+"} className='addAdvertiser' onClick={() => { navigate("/adminDashboard/advertisers/create") }} />

                <div className='adminDashboardScreensTableContainer'>
                    {advertisers.length > 0 && <table className='adminDashboardScreensTable'>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Company Name</th>
                                <th>Active</th>
                            </tr>
                        </thead>
                        <tbody>
                            {advertisers.map(advertiser => (
                                <tr key={advertiser.AdvertiserId} onClick={() => { navigate('/adminDashboard/advertisers/details/' + advertiser.AdvertiserId) }}>
                                    <td>{advertiser.AdvertiserId}</td>
                                    <td>{advertiser.CompanyName}</td>
                                    <td><span className={advertiser.Active === true && 'activeStatus' || 'inactiveStatus'}>{advertiser.Active === true && 'Active' || 'Inactive'}</span></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
                </div>
            </div>
    )
}
