import React, { useEffect } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom';
import './AdminDashboardUsers.css';

export default function AdminDashboardUsers(props) {

  useEffect(() => {
    return () => {
      
    }
  }, [])
  
  return (

    <div className='adminDashboardContainer'>
        <Outlet />
    </div>
  )
}
