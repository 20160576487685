import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { getUserDashboardData } from '../../../../services/getUserDashboardData';
import './UserDashboardHome.css'
export default function UserDashboardHome() {
    const { state } = useLocation();
    const navigate = useNavigate();
    var abbreviate = require('number-abbreviate')

    const [screensData, setScreensData] = useState([])
    const [screensActivity, setScreensActivity] = useState([])
    const token = secureLocalStorage.getItem("tk");
    function convertGMTToLocal(gmtTime) {
        const date = new Date(gmtTime);
        var localTime = date.toLocaleString();
        return localTime;
    }
    useEffect(() => {

        if (state?.userId && state?.userId !== "") {
            getUserDashboardData(token, state.userId).then(response => {
                setScreensData(response.data.screensData)
                setScreensActivity(response.data.screensHoursActive);
                console.log(response.data)

            });
        }
        else {
            navigate("/")
        }

    }, [])



    return (
        <>
            <span className='userDashboardTitle'>Your Screen Stats</span>

            {screensData.map((screen, id) => (
                <div onClick={() => {
                    navigate("/user/userDashboard/screenInfo", { state: { phoneNumber: state.phoneNumber, token: secureLocalStorage.getItem("tk"), userId: state.userId, screenId: screen.ScreenId }});
                }}className='screenContainer'>
                <span className='userDashboardScreenId'>Screen Id: #{screen.ScreenId}</span>
                <span className='userDashboardScreenDetail'>Total Earned Revenue: ${abbreviate(screen.TotalCostSum || "0", 2)}</span>
                <span className='userDashboardScreenDetail'>Total Paid: ${abbreviate(screen.TotalCostSumPaid || "0", 2)}</span>
                <span className='userDashboardScreenDetail'>Total Minutes Active: {(screensActivity[id].TotalMinutesActive).toFixed(1)}</span>
                <span className='userDashboardScreenDetail'>Last Active (GMT): {convertGMTToLocal(screen.LastActive) || screen.LastActive}</span>
            </div>))}
            <div className='screenContainer addScreenButton' onClick={() => { navigate("/user/linkNewScreen/", { state: { "phoneNumber": state.phoneNumber } }) }}>
                <span className='addButtonText'>+</span></div>
        </>
    )
}
