import React,{useState, useEffect} from 'react'
import { Routes, Route, Outlet, useOutlet } from "react-router-dom";
import "./AdvertiserDashboard.css"
import { useLocation } from 'react-router-dom';
import AdvertiserNav from '../AdvertiserNav/AdvertiserNav';


export default function AdvertiserDashboard() {
  const { state } = useLocation();
  useEffect(() => {
 
        if (localStorage.getItem("name"))
            return;
      localStorage.setItem("name", state.name)
    
      return () => {
        
      }
    }, [])
    
  return (
      <div>
          <AdvertiserNav></AdvertiserNav>

        <Outlet />
          </div>
              )
}
