import React, {useState, useEffect} from 'react'
import './Home.css';
import invertDisplay from '../../../assets/images/invertdisplay1.png';
import chevronIcon from '../../../assets/images/chevron.png';
import PhoneInput from 'react-phone-number-input/input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { parsePhoneNumber } from 'react-phone-number-input'
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useWindowDimensions from '../../../services/useWindowDimensions';
export default function Home() {
  const [phoneNumber, setPhoneNumber] = useState();
  const [pNoError, setPNoError] = useState(false);
  const [agree, setAgree] = useState(false);
  const [error, setError] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);
  const { height, width } = useWindowDimensions();

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
   
    if (state) {
      if (state.phoneNumber)
        setPhoneNumber(state.phoneNumber)
    }
  }, []);



  function onSubmit(event) {
    event.preventDefault();
    if (!agree ) {
      setError(true);
      return
    }
    if (!isValidPhoneNumber(phoneNumber) || parsePhoneNumber(phoneNumber).country != 'US') {
      setPNoError(true)
    }
    else {
      setPNoError(false);
      navigate("/user/register/", {state:{phoneNumber: phoneNumber}});
    }
  }
  return (
    <div>
      {width > 706 &&
        <div className='nav'>
          <div className='contactUs' onClick={() => { navigate('/ContactUs') }}>Contact Us</div>
          <div className='title' onClick={() => navigate("/")}>INVERT</div>
          <div className="placeholder" onClick={() => navigate("/userLogin")}>Login</div>
        </div>
      }
      {width <= 706 &&

        <div className='mobilenav'>
          <div className=' nav'>
            <div className='title mobilenavtitle' onClick={() => navigate("/")}>INVERT</div>
            <div className='chevron mobilenavchevron' onClick={() => setMobileNav(!mobileNav)}><img className='chevronIconnav' src={chevronIcon} /></div>
          </div>
          <div className='mobilenavmenu' style={{ display: mobileNav && 'block' || 'none' }}>
            <div className='mobilenavmenuItem'>
              <span className='mobilenavmenuItemTitle' onClick={() => { navigate('/ContactUs') }}>Contact Us</span>
            </div>
            <div className='mobilenavmenuItem' onClick={() => navigate("/userLogin")}>
              <span className='mobilenavmenuItemTitle' >Login</span>
            </div>
          </div>
        </div>
      }
      <div className='registrationContainer'>
        <div className='registrationBlock'>
          <form onSubmit={onSubmit}>
          <span className='registrationTitle'>Register To<br /> Host a Screen</span>
            <PhoneInput required 
            className='mobilePhoneRegistrationInput'
            country="US"
            defaultCountry="US"
            national={"false"}
              value={phoneNumber}
              onChange={setPhoneNumber}
            placeholder='Mobile Phone Number'/>
          <input type={"submit"} value={"Apply"}  className='phoneNumberApplyBtn'/>
          <div className="agreeTOSContainer">
            <input required type="checkbox" value={agree} onChange={()=>{setAgree(!agree)}} id="agreeTOS" className='agreeTOSCheckBox' name="agreeTOS" />
          <label id='agreeTOS' htmlFor="agreeTOS"> I agree to INVERT's Terms of Service</label>
            </div>
            {pNoError &&
              <span className='pNoError'>Please enter a valid US phone number.</span>
            }
            {error && <span className='errorPhoneVerification'>You must agree to INVERT's Terms of Service to continue.</span>}
          </form>

          </div>
        <img className='invertDisplayImg' src={invertDisplay} alt="invertDisplay" />
        <div>
        </div>
        </div>

    </div>
  )

          }