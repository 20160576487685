import React, { useState, useEffect } from 'react'
import './LinkNewScreen.css';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getIP } from '../../../services/getIP';
import { linkScreen } from '../../../services/linkScreen';
import { useSelector } from 'react-redux';
import { getToken } from '../../../features/token/tokenSaver';
import secureLocalStorage from 'react-secure-storage';
import { RingLoader } from 'react-spinners';

export default function LinkNewScreen() {
    const [screenCode, setScreenCode] = useState('');
   const [loading, setLoading] =useState(false);
    const { state } = useLocation();
    let token = secureLocalStorage.getItem("tk");

const [error, setError] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (!token) {
            token = secureLocalStorage.getItem("tk")
        }
        if (state) {
            if (!state.phoneNumber) {
                navigate('/')
            }
        }
        else {
            navigate('/')

        }
        return () => {

        }
    }, []);

 function screenCodeOnChange(value){
    var reg = /^\d+$/;
    if(value === ''){
        setScreenCode('');
        return;
    }
   if (reg.test(value)){
    setScreenCode(value);
    return;
   }
   else {
    return;
   }
 }
 function onSubmit(e){
     e.preventDefault();
     setLoading(true)
    if(screenCode.length === 0) {
        setError(true);
        return;
    }
        linkScreen(state.phoneNumber, screenCode, token).then(result=>{
            setError(false)
            setLoading(false)

            navigate('/user/todo',{ state: { phoneNumber: state.phoneNumber.replace('+1', '') } })
            
        }).catch(e=>{
            setError(true);
            setLoading(false)

return;
        })
 }
    return (
        <form onSubmit={onSubmit} className='linkNewScreenContainer'>
     <div className='nav navlinkNew'>
                <div className='todoPageNavTitle' onClick={()=> navigate("/user/todo",  { state: { phoneNumber: state.phoneNumber.replace('+1', '') }})}>INVERT</div>
            </div>
            
           <span className='linkNewScreenTitle'>Link A New Screen</span>
           <div className='stepsContainer'>
            <span className='stepsTitle'>Before Linking:</span>
            <ul className='stepsList'>
                <li>Turn on your Screen</li>
                <li>Complete the on-screen Wi-Fi setup</li>
                <li>Connect to the same network as your screen</li>
            </ul>
           </div>
           <input className='screenCode' type='number' value={screenCode} onChange={(e)=>{screenCodeOnChange(e.nativeEvent.target.value)}} placeholder='Screen Code'/>
            {!loading && <span className='searchButton' onClick={(e) => onSubmit(e)}>Search & Pair</span>}
            {loading && <RingLoader
                color={"#fffff"}
                loading={true}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
                className='searchingSpinner'
            />}
                 {error && <span className='errorText'>Please check the screen code and try again</span>}
                 
                   <span className='backButton' onClick={()=> navigate("/user/todo",  { state: { phoneNumber: state.phoneNumber.replace('+1', '') } })}>Back</span>
        </form>
    )
}
