import React, {useMemo, useEffect, useState} from 'react'
import "./AdminDashboardUsersTable.css"
import { getScreenData } from '../../../../services/getScreenData';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { getToken } from '../../../../features/token/tokenSaver';
import { getAllUsers } from '../../../../services/getAllUsers';
export default function AdminDashboardUsersTable(props) {
    const [page, setPage] = useState(0);
    const [screenData, setScreenData] = useState([]);
    const [forwardDisabled, setForwardDisabled] = useState(false);
    const [max, setMax] = useState(1);
    const navigate = useNavigate();
    const token = useSelector(getToken)

    useEffect(() => {
            populateTable(true);

      return () => {
        
      }
    }, [])
    function populateTable(forward, fromSearch) {
        setScreenData([]);

        getAllUsers(token, (forward && page+1 || page-1), 10).then(response => {
            setScreenData(response.data.users);
            if(forward){
            setPage(page + 1);}
            else {
                setPage(page - 1);
            }
            setMax(Math.ceil(response.data.count / 10))
            if (Math.ceil(response.data.count / 10) <= (forward && page + 1 || page - 1)) {
                setForwardDisabled(true)
            }
            else {
                setForwardDisabled(false)

            }
        }).catch(e => {
            console.log(e)
        });

    }


    return (

        <div className='AdminDashboardUsersTableContainer'>
            {props.data && <table className='AdminDashboardUsersTable'>
                <thead>
                    <tr>
                        <th>User Id</th>
                        <th>First Name</th>
                        <th>Application Status</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map(user => {
                        console.log(user)
                        return <tr key={user.UserId} onClick={() => {
                            props.screenDetails(user.UserId);

                        }}>
                            <td>{user.UserId}</td>
                            <td>{user.FName}</td>
                            <td >{["Incomplete", "Approved", "Denied", "In Review", "Active"][user.ApplicantStatusId - 1]}</td>
                        </tr>
                    })}
                </tbody>
            </table>}
            {!props.data && <table className='AdminDashboardUsersTable'>
            <thead>
                <tr>
                        <th>User Id</th>
                        <th>First Name</th>
                        <th>Application Status</th>
               </tr>
            </thead>
            <tbody>
                    {screenData.map(screen => {
                        return <tr key={screen.UserId} onClick={() => {
                            props.screenDetails(screen.UserId);

                        }}>
                            <td>{screen.UserId}</td>
                            <td>{screen.Fname}</td>
                            <td >{["Incomplete", "Approved", "Denied", "In Review", "Active"][screen.ApplicantStatusId - 1]}</td>
                        </tr>
                    })}
            </tbody>
            </table>}
            <div className='tablePaginationContainer'>
                <span className='paginationIcon' onClick={() => {
                   
                    populateTable(false);
                }} disabled={page <= 1}>{'<'}</span>
                <input className='page' max={max} min={1} type={"number"} onChange={async (e) => {
                  await  setPage(e.nativeEvent.target.value);
                    populateTable();
                }} placeholder={page}/>
                <span className='paginationIcon' onClick={() => {
                    populateTable(true);
                }} disabled={forwardDisabled}>{'>'}</span>
            </div>
        </div>   )
}
