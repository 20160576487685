import React,{useState, useEffect} from 'react'
import { checkEmptyObj } from '../../../../services/checkEmptyObj';
import { createAdvertiser } from '../../../../services/createAdvertiser';
import './AdvertiserDetails.css';
import { useSelector } from 'react-redux'
import { getToken } from '../../../../features/token/tokenSaver';
import ReactJsAlert from "reactjs-alert"
import { useNavigate } from 'react-router-dom';
import leftChevron from '../../../../assets/images/leftChevron.png';

import { useParams } from 'react-router';
import { getAdvertiserDetails } from '../../../../services/getAdvertiserDetails';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ToggleButton from 'react-toggle-button'
import { toggleAdvertiserActive } from '../../../../services/toggleAdvertiserActive';
import { toggleAdvertisment } from '../../../../services/toggleAdvertisement';

export default function AdvertiserDetails() {
  const [advertiserData, setAdvertiserData] = useState({})
  const [accountActive, setAccountActive] = useState(false);
  const token = useSelector(getToken);
  const navigate = useNavigate();
  const { id } = useParams();
  var abbreviate = require('number-abbreviate')
  function getAdvDetails() {
    getAdvertiserDetails(token, id).then(response => {
      setAdvertiserData(response.data);
      setAccountActive(response.data?.advertiser?.Active)
    })
}
 useEffect(() => {
   getAdvDetails();
 
   return () => {
     
   }
 }, [])
 
  function convertGMTtoLocalDatetime(gmtDatetimeString) {
    // Parse the GMT datetime string to a Date object
    const gmtDate = new Date(gmtDatetimeString);

    // Get the local datetime in the user's timezone
    const localDatetime = gmtDate.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: false, // Use 24-hour format
    });

    return localDatetime;
  }
  function toggleAdvertiserStatus(newStatus) {
    let formatted = '';
    if (newStatus) {
      formatted= '1'
    }
    else {
      formatted = '0'
    }
    toggleAdvertiserActive(token, advertiserData?.advertiser?.AdvertiserId,formatted).then(response=> {
      setAccountActive(newStatus);
      getAdvDetails();

    })
  }
  function toggleAdvertisementStatus(newStatus, adId) {
    let formatted = '';
    if (newStatus) {
      formatted = '1'
    }
    else {
      formatted = '2'
    }
    toggleAdvertisment(token, adId, formatted).then(response => {
      getAdvDetails();

    })
  }
  return (
    <div>
      <div className='backContainer'>
      <img className='leftChevronAdvertiserDetails' onClick={() => { navigate('/adminDashboard/advertisers/home') }} src={leftChevron} />

      <span className='advertiserCompanyTitle'>{advertiserData?.advertiser?.CompanyName}</span>
      </div>
      <Tabs>
        <TabList>
          <Tab>User Details</Tab>
          <Tab>Campaigns</Tab>
        </TabList>
        <TabPanel>

          <span className='tabTitle'>User Details</span>
        <div className='userDetailsItem'>
          <span className='userDetailsTitle'>Id</span>
          <span className='userDetailsData'>{advertiserData?.advertiser?.AdvertiserId}</span>
        </div>
        <div className='userDetailsItem'>
        
        </div>
        <div className='userDetailsItem'>
          <span className='userDetailsTitle'>First Name</span>
          <span className='userDetailsData'>{advertiserData?.advertiser?.Fname}</span>
        </div>
        <div className='userDetailsItem'>
          <span className='userDetailsTitle'>Last Name</span>
          <span className='userDetailsData'>{advertiserData?.advertiser?.Lname}</span>
        </div>
        <div className='userDetailsItem'>
          <span className='userDetailsTitle'>Company Name</span>
          <span className='userDetailsData'>{advertiserData?.advertiser?.CompanyName}</span>
        </div>
        <div className='userDetailsItem'>
          <span className='userDetailsTitle'>Account Created Date</span>
          <span className='userDetailsData'>{convertGMTtoLocalDatetime(advertiserData?.advertiser?.CreatedDate)}</span>
        </div>
        <div className='userDetailsItem'>
          <span className='userDetailsTitle'>Email</span>
          <span className='userDetailsData'>{advertiserData?.advertiser?.Email}</span>
        </div>
        <div className='userDetailsItem'>
          <span className='userDetailsTitle'>Account Status</span>
            <span className='userDetailsData'>{accountActive && 'Active'}{!accountActive && 'Inactive'}</span>
            <ToggleButton
              value={accountActive || false}
              onToggle={(value) => {
                toggleAdvertiserStatus(!value);
              }} />
          </div>
        </TabPanel>
        <TabPanel>

          <span className='tabTitle'>Advertisements</span>
          <table className='adminDashboardScreensTable'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Status</th>
                <th>Total Budget</th>
              </tr>
            </thead>
            <tbody>
              {advertiserData?.campaigns?.map(campaign => (
                <tr onClick={() => { navigate('/adminDashboard/advertisers/campaignDetails', { state: {adId: campaign.AdvertisementId}})}} key={campaign.AdvertisementId}>
                  <td>{campaign.AdvertisementId}</td>
                  <td>{campaign.AdvertisementName}</td>
                  <td>{['Enabled', 'Disabled', 'Pending'][campaign.StatusId - 1]}   {(campaign.StatusId == 1 || campaign.StatusId == 2) && <ToggleButton className="toggleBtn"
                    value={campaign.StatusId == 1}
                    onToggle={(value) => {
                      toggleAdvertisementStatus(!value, campaign.AdvertisementId);
                    }} />}</td>

                  <td>{abbreviate(campaign.CurrentBudget)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TabPanel>
      </Tabs>
    </div>
  )
}
