import React,{useState} from 'react'
import { checkEmptyObj } from '../../../../services/checkEmptyObj';
import { createAdvertiser } from '../../../../services/createAdvertiser';
import './CreateAdvertiser.css';
import { useSelector } from 'react-redux'
import { getToken } from '../../../../features/token/tokenSaver';
import ReactJsAlert from "reactjs-alert"
import { useNavigate } from 'react-router-dom';

export default function CreateAdvertiser() {
  const [advertiserData, setAdvertiserData] = useState({ fname: "", lname: "", email: "",companyName:"", password:""})
  const token = useSelector(getToken);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  function onChange(propertyName, newValue) {
    setAdvertiserData({ ...advertiserData, [propertyName]: newValue });
  }
  function onSubmit(event) {
    event.preventDefault();
    if (checkEmptyObj(advertiserData)) {
      setError(true);
      return;
    }
    else {
      createAdvertiser(advertiserData, token).then(result => {
        navigate("/adminDashboard/advertisers/home");

      }).catch(e => {
        setError(true);
        setErrorCode(e.message)
      })
    }
  }
  return (
    <div>
      <ReactJsAlert
        status={error} // true or false
        type={"error"} // success, warning, error, info
        title={"There was an error submitting your form"}
        quotes={true}
        quote={errorCode}
        Close={() => setError(false)}
        color="#1E1E1E"
      
      />
      <span className='registeredScreensTitle advertiserListTitle'>Register an Advertiser</span>
      <form onSubmit={onSubmit} className='advertiserRegisterContainer'>
        <div className='advertiserRegisterItemfull'>
          <span className='advertiserRegisterItemTitle'>Advertiser Full Company Name</span>
          <input required max={50} onChange={(e) => { onChange("companyName", e.nativeEvent.target.value) }} className='advertiserRegisterItemInput' placeholder='Advertiser Full Company Name' type={"text"}/>
        </div>
        <div className='advertiserRegisterItemhalfright'>
          <span className='advertiserRegisterItemTitle'>First Name</span>
          <input required max={50} onChange={(e) => { onChange("fname", e.nativeEvent.target.value) }} className='advertiserRegisterItemInput' placeholder='First Name' type={"text"} />
        </div>
        <div className='advertiserRegisterItemhalfleft'>
          <span className='advertiserRegisterItemTitle'>Last Name</span>
          <input required max={50} onChange={(e) => { onChange("lname", e.nativeEvent.target.value) }} className='advertiserRegisterItemInput' placeholder='Last Name' type={"text"} />
        </div>
        <div className='advertiserRegisterItemhalfright'>
          <span className='advertiserRegisterItemTitle'>Email</span>
          <input required max={100} onChange={(e) => { onChange("email", e.nativeEvent.target.value) }} className='advertiserRegisterItemInput' placeholder='Email' type={"email"} />
        </div>
        <div className='advertiserRegisterItemhalfleft'>
          <span className='advertiserRegisterItemTitle'>Password</span>
          <input required max={50} onChange={(e) => { onChange("password", e.nativeEvent.target.value) }} className='advertiserRegisterItemInput' placeholder='Password' type={"password"} />
        </div>
        <input  type={"submit"} className='advertiserRegisterSubmitBtn' value='Create Advertiser'/>
      </form>
    </div>
  )
}
