import React, {useState, useEffect} from 'react'
import './AdminNav.css';
import pfp from '../../../assets/images/pfp.png';
import home from '../../../assets/images/home1.png';
import logoutImg from '../../../assets/images/logout.png';
import advertisers from '../../../assets/images/advertisers.png';
import monitor from '../../../assets/images/monitor.png';
import { useNavigate } from 'react-router-dom';

export default function AdminNav(props) {
    const [homeActive, setHomeActive] = useState(sessionStorage.getItem("homeActive"));
    const [advertisersActive, setAdvertisersActive] = useState(sessionStorage.getItem("advertisersActive"));
    const [screensActive, setScreensActive] = useState(sessionStorage.getItem("screensActive"));
    const [usersActive, setUsersActive] = useState(sessionStorage.getItem("usersActive"));
 const [name, setName] = useState(localStorage.getItem("name"));
    const navigate = useNavigate();

    function activateHome() {
        setAdvertisersActive('false');
        setScreensActive('false');
        setHomeActive('true');
        setUsersActive('false');

        sessionStorage.setItem("homeActive", true);
        sessionStorage.setItem("advertisersActive", false);
        sessionStorage.setItem("screensActive", false);
        sessionStorage.setItem("usersActive", false);

        navigate('/adminDashboard/home')

    }
    function activateAdvertisers() {
        setAdvertisersActive('true');
        setScreensActive('false');
        setHomeActive('false');
        setUsersActive('false');

        sessionStorage.setItem("homeActive", false);
        sessionStorage.setItem("advertisersActive", true);
        sessionStorage.setItem("screensActive", false);
        sessionStorage.setItem("usersActive", false);

        navigate('/adminDashboard/advertisers')
        
    }
    function activateScreens() {
        setAdvertisersActive('false');
        setScreensActive('true');
        setHomeActive('false');
        setUsersActive('false');

        sessionStorage.setItem("homeActive", false);
        sessionStorage.setItem("advertisersActive", false);
        sessionStorage.setItem("screensActive", true);
        sessionStorage.setItem("usersActive", false);

        navigate('/adminDashboard/screens')

    }
    function activateUsers() {
        setAdvertisersActive('false');
        setScreensActive('false');
        setHomeActive('false');
        setUsersActive('true');
        sessionStorage.setItem("homeActive", false);
        sessionStorage.setItem("advertisersActive", false);
        sessionStorage.setItem("screensActive", false);
        sessionStorage.setItem("usersActive", true);
        navigate('/adminDashboard/users')

    }   
    function logout() {
        localStorage.removeItem("refreshToken");
        navigate("/adminLogin")
    }
    useEffect(() => {
        if (!sessionStorage.getItem("advertisersActive") || !sessionStorage.getItem("homeActive") || !sessionStorage.getItem("screensActive") || !sessionStorage.getItem("usersActive")) {
            sessionStorage.setItem("homeActive", true);
            sessionStorage.setItem("advertisersActive", false);
        sessionStorage.setItem("usersActive", false);

            sessionStorage.setItem("screensActive", false);
        }
    
      return () => {
        
      }
    }, [])
    
  return (
      <div className='adminNav'>
          <div className='adminNavTitleContainer'>
              <span className='adminNavTitle'>INVERT</span>
              <span className='adminNavSubTitle'>Dashboard</span>
          </div>
          <div className='adminProfile'>
              <img src={pfp} className='adminpfp' />
              <div className='adminUserDetails'>
              <span className='adminName'>{name}</span>
              <span className='adminRole'>Admin</span>
              </div>
          </div>
          <div onClick={activateHome} className={`adminNavItem ${homeActive === 'true'&& 'active'}`}>
              <img className='adminNavItemImg' src={home}/>
              <span className='adminNavItemTitle'>Home</span>
          </div>
          <div onClick={activateAdvertisers} className={`adminNavItem ${advertisersActive === 'true' && 'active'}`}>
              <img className='adminNavItemImg' src={advertisers} />
              <span className='adminNavItemTitle'>Advertisers</span>
          </div>
          <div onClick={activateScreens} className={`adminNavItem ${screensActive === 'true' && 'active'}`} >
              <img className='adminNavItemImg' src={monitor} />
              <span className='adminNavItemTitle'>Screens</span>
          </div>
          <div onClick={activateUsers} className={`adminNavItem ${usersActive === 'true' && 'active'}`} >
              <img className='adminNavItemImg' src={monitor} />
              <span className='adminNavItemTitle'>Users</span>
          </div>
          <div className='adminNavItem logout' onClick={() => { logout(); }}>
              <img className='adminNavItemImg' src={logoutImg}  />
              <span className='adminNavItemTitle'>Logout</span>
          </div>
    </div>
  )
}
