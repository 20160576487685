import React, {useState, useEffect} from 'react'
import './AdvertiserNav.css';
import pfp from '../../../assets/images/pfp.png';
import home from '../../../assets/images/home1.png';
import logoutImg from '../../../assets/images/logout.png';
import advertisers from '../../../assets/images/advertisers.png';
import monitor from '../../../assets/images/monitor.png';
import { useNavigate } from 'react-router-dom';

export default function AdvertiserNav(props) {
    const [homeActive, setHomeActive] = useState(sessionStorage.getItem("homeActive"));
    const [advertisersActive, setAdvertisersActive] = useState(sessionStorage.getItem("advertisersActive"));
    const [screensActive, setScreensActive] = useState(sessionStorage.getItem("screensActive"));
 const [name, setName] = useState(localStorage.getItem("name"));
    const navigate = useNavigate();

    function activateHome() {
        setAdvertisersActive('false');
        setScreensActive('false');
        setHomeActive('true');
        sessionStorage.setItem("homeActive", true);
        sessionStorage.setItem("advertisersActive", false);
        sessionStorage.setItem("screensActive", false);
        navigate('/advertiserDashboard/home')

    }
    function activateAdvertisers() {
        setAdvertisersActive('true');
        setScreensActive('false');
        setHomeActive('false');
        sessionStorage.setItem("homeActive", false);
        sessionStorage.setItem("advertisersActive", true);
        sessionStorage.setItem("screensActive", false);
        navigate('/advertiserDashboard/campaigns')
    }
    function activateScreens() {
        setAdvertisersActive('false');
        setScreensActive('true');
        setHomeActive('false');
        sessionStorage.setItem("homeActive", false);
        sessionStorage.setItem("advertisersActive", false);
        sessionStorage.setItem("screensActive", true);
        navigate('/advertiserDashboard/newCampaign')

    }
    function logout() {
        localStorage.removeItem("refreshToken");
        navigate("/advertiserLogin")
    }
    useEffect(() => {
        if (!sessionStorage.getItem("advertisersActive") || !sessionStorage.getItem("homeActive") || !sessionStorage.getItem("screensActive")) {
            sessionStorage.setItem("homeActive", true);
            sessionStorage.setItem("advertisersActive", false);
            sessionStorage.setItem("screensActive", false);
            navigate('/advertiserDashboard/home')

        }
        if (sessionStorage.getItem("advertisersActive") == "true") {
            navigate('/advertiserDashboard/campaigns')

        }
        if (sessionStorage.getItem("homeActive") == "true") {
            navigate('/advertiserDashboard/home')

        }
        if (sessionStorage.getItem("screensActive") == "true") {
            navigate('/advertiserDashboard/newCampaign')

        }
      return () => {
        
      }
    }, [])
    
  return (
      <div className='adminNav'>
          <div className='adminNavTitleContainer'>
              <span className='adminNavTitle'>INVERT</span>
              <span className='adminNavSubTitle'>Advertiser Dashboard</span>
          </div>
          <div className='adminProfile'>
              <img src={pfp} className='adminpfp' />
              <div className='adminUserDetails'>
              <span className='adminName'>{name}</span>
              <span className='adminRole'>Advertiser</span>
              </div>
          </div>
          <div onClick={() => { activateHome() }} className={`adminNavItem ${homeActive === 'true'&& 'active'}`}>
              <img className='adminNavItemImg' src={home}/>
              <span className='adminNavItemTitle'>Home</span>
          </div>
          <div onClick={() => { activateAdvertisers() }} className={`adminNavItem ${advertisersActive === 'true' && 'active'}`}>
              <img className='adminNavItemImg' src={advertisers} />
              <span className='adminNavItemTitle'>My Campaigns</span>
          </div>
          <div onClick={() => { activateScreens() }} className={`adminNavItem ${screensActive === 'true' && 'active'}`} >
              <img className='adminNavItemImg' src={monitor} />
              <span className='adminNavItemTitle'>New Campaign</span>
          </div>
          <div className='adminNavItem logout' onClick={() => { logout();}}>
              <img className='adminNavItemImg' src={logoutImg} />
              <span className='adminNavItemTitle'>Logout</span>
          </div>
    </div>
  )
}
