import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import './UserDashboardScreenInformation.css';
import { getUserDashboardScreenInfo } from '../../../../services/getUserDashboardScreenInfo';
import { updateScreenContent } from '../../../../services/updateScreenContent';
import ToggleButton from 'react-toggle-button'
import ReactjsAlert from 'reactjs-alert';
import { toggleScreenActive } from '../../../../services/toggleScreenOn';

export default function UserDashboardScreenInformation() {
  var abbreviate = require('number-abbreviate')
  const [screensActivity, setScreensActivity] = useState([]);
  const { state } = useLocation();
  const [ stats, setStats ] = useState({});
  const [hoursActive, setHoursActive] = useState(0);
  const [screenDataForGraph, setScreenDataForGraph] = useState({});
  const [contentToReplace, setContentToReplace] = useState();
  const [alertActive, setAlertActive] = useState(false);
  const [screenOn, setScreenOn] = useState(false);
  const [screenContent, setScreenContent] = useState([]);
  const token = secureLocalStorage.getItem("tk");
  function formatLastActive(lastActiveString) {
    console.log(lastActiveString)
    const date = new Date(lastActiveString);
    return date.toLocaleString();
}
  function fileUploadOnChange(e) {
    // clean up earliest items
    var myFiles = {}
    // set state of files to false until each of them is processed
    var isFilesReady = false

    var files = document.querySelector("#" + e.target.id).files;
    if (files[0].size > 3145728) {
      alert("This file is too large, please upload content smaller than 3MB");
      return;
    }
    var reader = new FileReader();
    var error = false;
    //Read the contents of Image File.
    reader.readAsDataURL(files[0]);
    reader.onload = function (te) {

      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = te.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        // var ratio = Math.round((this.width / this.height) * 100) / 100;
        // if (ratio !== .56) {
        //   alert("File aspect ratio must be 9:16");
        //   document.querySelector("#" + e.target.id).value = "";

        //   error = true;
        //   return false;
        // }
      };
    };
    if (error)
      return;
    if (files[0].name.split(".")[files[0].name.split(".").length - 1] !== "jpg" && files[0].name.split(".")[files[0].name.split(".").length - 1] !== "png" ) {
      alert("Please upload a file with the file type JPG or PNG");
      document.querySelector("#" + e.target.id).value = ""
      return;
    }
    const filePromises = Object.entries(files).map(item => {
      return new Promise((resolve, reject) => {
        const [index, file] = item
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = function (event) {
          // handle reader success
          myFiles['picture'] = btoa(event.target.result);
          const newFileDataObj = btoa(event.target.result);
          updateContent(newFileDataObj);
          files = [];
          resolve()
        };

        reader.onerror = function () {
          reject()
        };
      })
    })

    Promise.all(filePromises)
      .then(() => {
        // if each file processed successfuly then set our state to true
        isFilesReady = true
      })
      .catch((error) => {
        console.log(error)
      })
  }
  function updateContent(fileData) {
    const contentInformation = {content: fileData, userId: state.userId, screenId: state.screenId, contentId: contentToReplace}
    updateScreenContent(contentInformation, token).then(response => {
      if (contentToReplace) {
        window.location.reload(true);
      }
      updateScreen();
    }).catch(e => {
      console.error(e);
    })
  }
  function toggleScreen() {
    toggleScreenActive({setting: !screenOn, screenId: state.screenId}, token).then(response => {
      updateScreen();
      setAlertActive(false);

    }).catch(e => {
      console.error(e)
    })
  }
  function updateScreen() {
    getUserDashboardScreenInfo(token, state.userId, state.screenId)
      .then(result => {
        setStats(result.data.screensData[0]);

        setHoursActive(result.data.screensHoursActive[0].TotalMinutesActive);
        setScreenDataForGraph(result.data.setScreenDataForGraph);
        setScreenContent(result.data.screenContent ? result.data.screenContent : []);
        setScreenOn(result.data.turnedOn.TurnedOn);
      }).catch((e) => {
        console.error(e)
      });
  }
  useEffect(()=>{
    updateScreen();
  }, []);
  return (
    <div>

      <span className='userDashboardTitle'>Screen #{state.screenId}  <ToggleButton className="toggleButton"
        value={screenOn || false}
        onToggle={(value) => {
          setAlertActive(true);
        }} /><span className="active"></span></span>
      {alertActive &&<ReactjsAlert 
        status={alertActive} // true or false
        type={"warning"} // success, warning, error, info
        title={"Screen Power"}
        quotes={true}
        quote={`Are you sure you want to turn ${screenOn && "off" || "on"} your screen?`}
        Close={() => toggleScreen(!screenOn)}
        color="#1E1E1E"

      />}
      <div className='userDashboardScreenInfoQuickStats'>
        <div className='userDashboardScreenInfoQuickStatsTotalRev'>
          <span className='userDashboardScreenInfoQuickStatsTitle'>Total Revenue</span>
          <span className='userDashboardScreenInfoQuickStatsContent'>${stats.TotalCostSum}</span>

        </div>
        <div className='userDashboardScreenInfoQuickStatsTotalPaid'>
          <span className='userDashboardScreenInfoQuickStatsTitle'>Paid</span>
          <span className='userDashboardScreenInfoQuickStatsContent'>${stats.TotalCostSumPaid || 0}</span>

        </div>
        <div className='userDashboardScreenInfoQuickStatsTotalHrsActive'>
          <span className='userDashboardScreenInfoQuickStatsTitle'>Total Hours Active</span>
          <span className='userDashboardScreenInfoQuickStatsContent'>{((parseFloat(hoursActive))/60).toFixed(2)}</span>

        </div>
        <div className='userDashboardScreenInfoQuickStatsTotalLastActive'>
          <span className='userDashboardScreenInfoQuickStatsTitle'>Last Active</span>
          <span className='userDashboardScreenInfoQuickStatsContent'>{formatLastActive(stats.LastActive)}</span>

        </div>
      </div>
      <span className='userDashboardScreenInfoActivityGraphTitle'>Content</span>

      <div className='userDashboardScreenInfoContent'>
        <span className='bestSizeMessage'>Best Size: 1120px x 1536px</span>
        <input type="file" name="" id='input_file' hidden onChange={(e)=>{fileUploadOnChange(e)}}></input>
        {screenContent[0] && <img onClick={() => { setContentToReplace(screenContent[0].ContentId); document.getElementById("input_file").click() }} className='userDashboardScreenInfoContentImage' src={screenContent[0].ContentLink} key={screenContent[0].ContentLink} />}
        {!screenContent[0] && <div onClick={() => { setContentToReplace(null); document.getElementById("input_file").click()}} className='userDashboardScreenInfoContentEmptyContent'>
          <div className='userDashboardScreenInfoContentEmptyContentPlusText'>
            <span className='userDashboardScreenInfoContentEmptyContentPlus'>+</span>
            <span className='userDashboardScreenInfoContentEmptyContentText'>Click to add, or select content to replace</span>
          </div>
        </div>}
        {screenContent[1] && <img onClick={() => { setContentToReplace(screenContent[1].ContentId); document.getElementById("input_file").click() }} className='userDashboardScreenInfoContentImage' src={screenContent[1].ContentLink} key={screenContent[1].ContentLink} />}
        {!screenContent[1] && <div onClick={() => { setContentToReplace(null); document.getElementById("input_file").click() }} className='userDashboardScreenInfoContentEmptyContent'>
          <div className='userDashboardScreenInfoContentEmptyContentPlusText'>
            <span className='userDashboardScreenInfoContentEmptyContentPlus'>+</span>
            <span className='userDashboardScreenInfoContentEmptyContentText'>Click to add, or select content to replace</span>
          </div>
        </div>}
        {screenContent[2] && <img onClick={() => { setContentToReplace(screenContent[2].ContentId); document.getElementById("input_file").click() }} className='userDashboardScreenInfoContentImage' src={screenContent[2].ContentLink} key={screenContent[2].ContentLink} />}
        {!screenContent[2] && <div onClick={() => { setContentToReplace(null); document.getElementById("input_file").click() }} className='userDashboardScreenInfoContentEmptyContent'>
          <div className='userDashboardScreenInfoContentEmptyContentPlusText'>
            <span className='userDashboardScreenInfoContentEmptyContentPlus'>+</span>
            <span className='userDashboardScreenInfoContentEmptyContentText'>Click to add, or select content to replace</span>
          </div>
        </div>}
        {screenContent[3] && <img onClick={() => { setContentToReplace(screenContent[3].ContentId); document.getElementById("input_file").click() }} className='userDashboardScreenInfoContentImage userDashboardScreenInfoContentImageLast' src={screenContent[3].ContentLink} key={screenContent[3].ContentLink} />}
        {!screenContent[3] && <div onClick={() => { setContentToReplace(null); document.getElementById("input_file").click() }} className='userDashboardScreenInfoContentEmptyContent'>
          <div className='userDashboardScreenInfoContentEmptyContentPlusText'>
          <span className='userDashboardScreenInfoContentEmptyContentPlus'>+</span>
            <span className='userDashboardScreenInfoContentEmptyContentText'>Click to add, or select content to replace</span>
          </div>
        </div>}
      </div>
      <span className='userDashboardScreenInfoActivityGraphTitle'>Analytics</span>

      <div className='userDashboardScreenInfoActivityGraph'>
 
      </div>
    </div>
  )
}
