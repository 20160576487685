import { useEffect } from "react";
import {
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { createAdvertisement } from '../../../../../services/createAdvertisement';
import { useSelector } from 'react-redux'
import { getToken } from '../../../../../features/token/tokenSaver';
// This values are the props in the UI
const currency = "USD";
const style = { "layout": "vertical" };

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ setPendingRedirect, currency, showSpinner, cost, formData, navigate }) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const token = useSelector(getToken);

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
        
    }, [currency, showSpinner]);


    return (<>
        {(showSpinner && isPending) && <div className="spinner" />}
        <PayPalButtons
            style={style}
            disabled={false}
            forceReRender={[cost, currency, style]}
            fundingSource={undefined}
            createOrder={(data, actions) => {
                return actions.order
                    .create({
                        purchase_units: [
                            {
                                amount: {
                                    currency_code: currency,
                                    value: cost,
                                },
                            },
                        ],
                    })
                    .then((orderId) => {
                        // Your code here after create the order
                        return orderId;
                    });
            }}
            onApprove={function (data, actions) {
                return actions.order.capture().then(function () {
                    // Your code here after capture the order
                    setPendingRedirect(true);
                    createAdvertisement(formData, token).then(response => {
                        if (response.data.success == true) {
                            navigate("/advertiserDashboard/campaigns")
                        }
                    }).catch(error => {
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                        }
                    })
                });
            }}
        />
    </>
    );
}

export default function PaymentButton({ setPendingRedirect,data, cost, navigate }) {
    return (
        <div style={{ maxWidth: "750px", minHeight: "200px" }}>
           
                <ButtonWrapper
                    currency={currency}
                    showSpinner={true}
                    cost={cost}
                    formData={data}
                navigate={navigate}
                setPendingRedirect={setPendingRedirect}
                />
        </div>
    );
}