import React, { Component } from "react";

const parseJwt = (token) => {
    try {
        return JSON.parse(token.split('.')[1]);
    } catch (e) {
        return null;
    }
};

class AuthVerify extends Component {

    constructor(props) {

        super(props);

        props.history.listen(() => {

            if (props.token) {
                const decodedJwt = parseJwt(props.token);

                if (decodedJwt.exp * 1000 < Date.now()) {
                    props.logOut();
                }
            }
            else {
                props.logOut();

            }
        });
    }

    render() {
        return <div></div>;
    }
}

export default AuthVerify;