import React, { useState, useEffect } from 'react'
import './ApplicationUnderReview.css';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { checkEmptyObj } from '../../../services/checkEmptyObj';
import {createBusiness} from '../../../services/createBusiness';
import { getUserData } from '../../../services/getUserData';
import { useSelector } from 'react-redux';
import { getToken } from '../../../features/token/tokenSaver';
import secureLocalStorage from 'react-secure-storage';
export default function ApplicationUnderReview() {
    const { state } = useLocation();
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    let token = secureLocalStorage.getItem("tk");
    useEffect(() => {
        if (!token) {
            token = secureLocalStorage.getItem("tk")
        }
        if (state) {
            if (!state.phoneNumber) {
                navigate('/')
            }
              getUserData(state.phoneNumber, token).then(function (response) {
                setEmail(response.data.Email);


             
           
            })
                .catch(function (error) {
                    navigate('/')
                });
        }
        else {
            navigate('/')

        }
        return () => {

        }
    }, []);

    return (
        <div>
            <div className='nav'>
                <div className='todoPageNavTitle' onClick={()=> navigate("/user/todo",  { state: { phoneNumber: state.phoneNumber.replace('+1', '') }})}>INVERT</div>
            </div>
           <div className='underReviewMessageContainer'>
        <span className='underReviewMessageTitle'> Application Under Review</span>
        <span className='underReviewMessage'>
        Your application is under review.
                    You will receive emails at <span className='emailInMessage'>{email.replace(/ /g, '')}</span> with updates.
        </span>
           </div>
            <span className='underReviewHelpFooter'>Need Help? Send us an email at: <a href="mailto: inverthelp@gmail.com">inverthelp@gmail.com</a></span>
       </div>
    )
}
