import React,{useEffect, useState} from 'react'
import { useOutletContext } from 'react-router-dom';
import { searchScreenData } from '../../../../services/searchScreenData';
import AdminDashboardScreenDetails from '../AdminDashboardScreenDetails/AdminDashboardScreenDetails';
import AdminDashboardScreensTable from '../AdminDashboardScreensTable/AdminDashboardScreensTable'
import { useSelector } from 'react-redux'
import { getToken } from '../../../../features/token/tokenSaver';
export default function AdminDashboardScreenHome(props) {
  const [searchItems, setSearchItems] = useState(null);
  const [details, setScreenDetails] = useState(false);
  const [screenId, setScreenId] = useState(null);
  const [lastSearched, setLastSearched] = useState("");
  const token = useSelector(getToken)

    useEffect(() => {
    
      return () => {
        
      }
    }, [])
  function searchHandler(e) {
    if (e.keyCode == 13 && lastSearched !== e.target.value && !e.target.value.match(/^\s*$/)) {
      searchScreenData(token, e.target.value).then(response => {
        setSearchItems(response.data.screens);
        setLastSearched(e.target.value);
      })
    }
    else if (e.target.value.match(/^\s*$/)){
      setSearchItems(null);
    }
  }
  function screenDetails(screenId) {
    setScreenId(screenId);
    setScreenDetails(true);
  }
  function screensTable() {
    setScreenDetails(false);
  }
    return (
      <div >
        {!details &&<div className='adminDashboardScreenTable'>
          <span className='registeredScreensTitle'>Registered Screens</span>
          <input onKeyUp={(e) => { searchHandler(e); }} type={"text"} placeholder='Search' className='adminDashboardScreenPageSearchInput' />
          <AdminDashboardScreensTable screenDetails={screenDetails} data={searchItems}></AdminDashboardScreensTable>
        </div>
        }
        {details &&<div className='adminDashboardScreenDetails'>
          <AdminDashboardScreenDetails screensTable={screensTable}screenId={screenId}></AdminDashboardScreenDetails>
        </div>
        }
        </div>
  )
}
