import axios from "axios"
export function getUserDashboardScreenInfo(token, userId, screenId) {
    return axios.post(process.env.REACT_APP_API_URL + `/getScreenStats`,  {
        userId: userId,
        screenId: screenId
    },{
        headers: {
            'Authorization': `${token}`
        }
      
    });
}