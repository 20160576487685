import React, { useEffect, useState} from 'react'
import './AdminDashboardHome.css';
import { useOutletContext } from "react-router-dom";
import { getAds } from '../../../services/getAds';
import { Cell, PieChart } from 'recharts';
import ScreenActivityGuageChart from './ScreenActivityGaugeChart/ScreenActivityGuageChart';
import { getActiveScreens } from '../../../services/getActiveScreens';
import { useSelector } from 'react-redux'
import { getToken } from '../../../features/token/tokenSaver';
export default function AdminDashboardHome(props) {
  const [adsShownToday, setAdsShownToday] = useState(null);
  const [adsShown, setAdsShown] = useState(null);
  const [screensActive, setScreensActive] = useState(null);
  const [totalScreens, setTotalScreens] = useState(null);
  const token = useSelector(getToken)

  useEffect(() => {
    getAds(token).then(response => {
      setAdsShown(response.data.adsShown);
      setAdsShownToday(response.data.adsShownToday);
    }).catch(e => {
      
    });
    getActiveScreens(token).then(response => {
      console.log(response.data)
      setScreensActive(response.data.activeScreens);
      setTotalScreens(response.data.totalScreens);
    }).catch(e => {

    });
    return () => {
      
    }
  }, [])
  
  return (

    <div className='adminDashboardContainer'>
      <div className='adminDashboardHomeContainer'>
     <div className='adminDashboardHomeGraph'> 
          <ScreenActivityGuageChart screensActive={screensActive} totalScreens={totalScreens}></ScreenActivityGuageChart>

     </div>
        <div className='adminDashboardHomeStatsContainer'>
        <div className='adminDashboardHomeAdsDisplayedToday'>
          <span className='adminDashboardHomeAdsDisplayedTodayTitle'>Ads Displayed Today</span>
          <span className='adminDashboardHomeAdsDisplayedTodayValue'>{adsShownToday}</span>
          </div>

          <div className='adminDashboardHomeAdsDisplayed'>
            <span className='adminDashboardHomeAdsDisplayedTitle'>Ads Displayed</span>
            <span className='adminDashboardHomeAdsDisplayedValue'>{adsShown}</span>
          </div>
          <div className='adminDashboardHomeAdsDisplayedToday activeScreens'>
            <span className='adminDashboardHomeAdsDisplayedTodayTitle '>Active Screens <div className='adminDashboardHomeAdsDisplayedTodayTitleLiveCircle'></div></span>
            <span className='adminDashboardHomeAdsDisplayedTodayValue'>{screensActive}</span>
          </div>

          <div className='adminDashboardHomeAdsDisplayed totalScreens'>
            <span className='adminDashboardHomeAdsDisplayedTitle'>Total Screens</span>
            <span className='adminDashboardHomeAdsDisplayedValue'>{totalScreens}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
