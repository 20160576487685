import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Pages/User/Home/Home';
import Registration from './Pages/User/Registration/Registration';
import NoPage from './Pages/NoPage';
import Verification from './Pages/User/Verification/Verification';
import Todo from './Pages/User/Todo/Todo';
import Login from './Pages/User/Login/Login';
import BusinessDetails from './Pages/User/BusinessDetails/BusinessDetails';
import FileUpload from './Pages/User/FileUpload/FileUpload';
import AdminLogin from './Pages/Admin/AdminLogin/AdminLogin';
import AdminDashboard from './Pages/Admin/AdminDashboard/AdminDashboard';
import AdminDashboardAdvertisers from './Pages/Admin/AdminDashboardAdvertisers/AdminDashboardAdvertisers';
import AdminDashboardHome from './Pages/Admin/AdminDashboardHome/AdminDashboardHome';
import AdminDashboardScreens from './Pages/Admin/AdminDashboardScreens/AdminDashboardScreens';
import AdminDashboardScreenHome from './Pages/Admin/AdminDashboardScreens/AdminDashboardScreenHome/AdminDashboardScreenHome';
import AdminDashboardScreenDetails from './Pages/Admin/AdminDashboardScreens/AdminDashboardScreenDetails/AdminDashboardScreenDetails';
import AdvertisersHome from './Pages/Admin/AdminDashboardAdvertisers/AdvertisersHome/AdvertisersHome';
import CreateAdvertiser from './Pages/Admin/AdminDashboardAdvertisers/CreateAdvertiser/CreateAdvertiser';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getToken } from './features/token/tokenSaver';
import AuthVerify from './services/jwtVerify';
import axios from "axios"
import AdvertiserDashboard from './Pages/Advertiser/AdvertiserDashboard/AdvertiserDashboard'
import { useEffect, useState, useCallback } from 'react';
import AdvertiserLogin from './Pages/Advertiser/AdvertiserLogin/AdvertiserLogin';
import AdvertiserDashboardHome from './Pages/Advertiser/AdvertiserDashboard/AdvertiserDashboardHome/AdvertiserDashboardHome';
import AdvertiserDashboardCampaigns from './Pages/Advertiser/AdvertiserDashboard/AdvertiserDashboardCampaigns/AdvertiserDashboardCampaigns';
import AdvertiserDashboardNewCampaign from './Pages/Advertiser/AdvertiserDashboard/AdvertiserDashboardNewCampaign/AdvertiserDashboardNewCampaign';
import { refreshJWT } from './services/refreshJWT';
import { useDispatch } from 'react-redux'
import { save } from './features/token/tokenSaver';
import LinkNewScreen from './Pages/User/LinkNewScreen/LinkNewScreen';
import ZelleDetails from './Pages/User/ZelleDetails/ZelleDetails';
import ApplicationUnderReview from './Pages/User/ApplicationUnderReview/ApplicationUnderReview';
import AdminDashboardUsersHome from './Pages/Admin/AdminDashboardUsers/AdminDashboardUsersHome/AdminDashboardUsersHome';
import AdminDashboardUsers from './Pages/Admin/AdminDashboardUsers/AdminDashboardUsers';
import AdminDashboardUsersDetails from './Pages/Admin/AdminDashboardUsers/AdminDashboardUsersDetails/AdminDashboardUsersDetails';
import AdvertiserDetails from './Pages/Admin/AdminDashboardAdvertisers/AdvertiserDetails/AdvertiserDetails';
import AdminDashboardAdvertiserCampaignDetails from './Pages/Admin/AdminDashboardAdvertisers/AdvertiserDetails/CampaignDetails/AdvertiserCampaignDetails';
import AdvertiserCampaignDetails from './Pages/Advertiser/AdvertiserDashboard/AdvertiserDashboardCampaigns/AdvertiserCampaignDetails/AdvertiserCampaignDetails';
import ContactUs from './Pages/ContactUs/ContactUs';
import UserDashboard from './Pages/User/UserDashboard/UserDashboard';
import secureLocalStorage from 'react-secure-storage';
import Landing from './Pages/Landing/Landing';
import UserDashboardHome from './Pages/User/UserDashboard/UserDashboardHome/UserDashboardHome';
import UserDashboardScreenInformation from './Pages/User/UserDashboard/UserDashboardScreenInformation/UserDashboardScreenInformation';

export default function App() {
  const token = useSelector(getToken);
  const location = useLocation();
  const navigate = useNavigate();
  const [useEffectRun, setUseEffectRun] = useState(0);
  const [adminTokenExp, setAdminTokenExp] = useState(localStorage.getItem("admin") === "true");
  const [advertiserTokenExp, setAdvertiserTokenExp] = useState(localStorage.getItem("advertiser") === "true");
  const dispatch = useDispatch();
  async function refreshToken() {
    var root = location.pathname.split("/")[1];
    if (root === "/" || root==="") {
      return;
    }
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split('.')[1]));
      } catch (e) {
        return null;
      }
    };
    const decodedJwt = parseJwt(localStorage.getItem("refreshToken"));
    let decodedTokenJwt = parseJwt(token);
    if (root !== "adminDashboard" && root !== "advertiserDashboard" && root !== "user" && root !== "adminLogin" && root !== "advertiserLogin") {
      return;
    }
    if (!decodedJwt) {

   
     if (localStorage.getItem("admin") === "true") {
        return navigate("/adminLogin");

      }
      else if (localStorage.getItem("advertiser") === "true") {
        return navigate("/advertiserLogin");

      }
      else {
        return navigate("/");
      }
    }
    if (!decodedTokenJwt) {
      decodedTokenJwt = parseJwt(secureLocalStorage.getItem("tk"))
    }
    const date = new Date();
    if ((decodedJwt.phoneNumber && date.getMilliseconds() > decodedTokenJwt?.exp)) {
     await refreshJWT(localStorage.getItem("refreshToken"), decodedJwt.phoneNumber).then(async (result) => {
       const newToken = result.data.token; 
       const saveToken = (newToken, dispatch) => new Promise((resolve, reject) => {
          // do anything here
          dispatch(save(newToken));
          secureLocalStorage.setItem("tk", newToken)
          resolve();
        });
        await saveToken(result.data.token, dispatch).then(() => {
          localStorage.setItem("refreshToken", result.data.newRefreshToken);

          // navigate(0);
        });


      }).catch(e => {
        setAdminTokenExp(true);
        setAdvertiserTokenExp(true);
        localStorage.setItem("admin", null);
        localStorage.setItem("advertiser", null);
        var root = location.pathname.split("/")[1];

        if (localStorage.getItem("admin") === "true") {
          return navigate("/adminLogin");

        }
        else if (localStorage.getItem("advertiser") === "true") {
          return navigate("/advertiserLogin");

        }
        else {
          return navigate("/")
        }


      });
    }
    else if ((date.getMilliseconds() > decodedTokenJwt?.exp)){
    await  refreshJWT(localStorage.getItem("refreshToken"), decodedJwt.email ).then(async (result) => {
      const newToken = result.data.token;

      const saveToken = (newToken, dispatch) => new Promise((resolve, reject) => {
          // do anything here
        dispatch(save(newToken));
        secureLocalStorage.setItem("tk", newToken)

          resolve();
        });
        await saveToken(result.data.token, dispatch).then(() => {
          localStorage.setItem("refreshToken", result.data.newRefreshToken);

          // navigate(0);
        });


      }).catch(e => {
        setAdminTokenExp(true);
        setAdvertiserTokenExp(true);
        localStorage.setItem("admin", null);
        localStorage.setItem("advertiser", null);
        var root = location.pathname.split("/")[1];

        if (localStorage.getItem("admin") === "true") {
          return navigate("/adminLogin");

        }
        else if (localStorage.getItem("advertiser") === "true") {
          return navigate("/advertiserLogin");

        }
        else {
          return navigate("/")
        }
   

      });
    }
    else {
      return;
    }
  }
  
  const stableEffect = useCallback(() => {
    setUseEffectRun(1);
    refreshToken()
  
  }, [token]);

  return (
    <>
      <Effect effect={stableEffect} />

    <Routes>
      

        <Route path="/" element={<Landing />} />
        <Route path="/userHome" element={<Home />} />
      <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/user/home" element={<Home />} />
        <Route path="/user/register/" element={<Registration />} />
        <Route path="/userVerification/" element={<Verification />} />
        <Route path="/user/userDashboard/" element={<UserDashboard />} >
          <Route path="" element={<UserDashboardHome />} />
          <Route path="/user/userDashboard/screenInfo" element={<UserDashboardScreenInformation />} />

          </Route>
        <Route path="/user/todo/" element={<Todo />} />
        <Route path="/userLogin/" element={<Login />} />
        <Route path="/user/businessDetails/" element={<BusinessDetails />} />
        <Route path="/user/linkNewScreen/" element={<LinkNewScreen />} />
        <Route path="/user/applicationUnderReview/" element={<ApplicationUnderReview />} />
        <Route path="/user/zelleDetails/" element={<ZelleDetails />} />
        <Route path="/user/fileUpload/" element={<FileUpload />} />
      <Route path="/adminLogin/" element={<AdminLogin />} />
      <Route path="/advertiserLogin/" element={<AdvertiserLogin />} />
      {!adminTokenExp &&

      <Route path="/adminDashboard/" element={<AdminDashboard />} >
          <Route path="" element={<AdminDashboardHome />} />
            <Route path="/adminDashboard/home" element={<AdminDashboardHome />} />
            <Route path="/adminDashboard/advertisers" element={<AdminDashboardAdvertisers />} >
            <Route path="" element={<AdvertisersHome />} />
            <Route path="/adminDashboard/advertisers/home"  element={<AdvertisersHome />} />
            <Route path="/adminDashboard/advertisers/create" element={<CreateAdvertiser />} />
            <Route path="/adminDashboard/advertisers/details/:id" element={<AdvertiserDetails />} />
            <Route path="/adminDashboard/advertisers/campaignDetails" element={<AdminDashboardAdvertiserCampaignDetails />} />
          </Route>
          <Route path="/adminDashboard/screens" element={<AdminDashboardScreens />} >
            <Route path="" element={<AdminDashboardScreenHome />} />
            <Route path="/adminDashboard/screens/home" element={<AdminDashboardScreenHome />} />
            <Route path="/adminDashboard/screens/details" element={<AdminDashboardScreenDetails />} />

          </Route>
             <Route path="/adminDashboard/users" element={<AdminDashboardUsers />} >
            <Route path="" element={<AdminDashboardUsersHome />} />
            <Route path="/adminDashboard/users/home" element={<AdminDashboardUsersHome />} />
            <Route path="/adminDashboard/users/details" element={<AdminDashboardUsersDetails />} />

          </Route>
        </Route>
      }
      {!advertiserTokenExp &&
        <Route path="/advertiserDashboard/" element={<AdvertiserDashboard />} >
          <Route path="" element={<AdvertiserDashboardHome />} />

          <Route path="/advertiserDashboard/home" element={<AdvertiserDashboardHome />} />
          <Route path="/advertiserDashboard/campaigns" element={<AdvertiserDashboardCampaigns />} />
          <Route path="/advertiserDashboard/newCampaign" element={<AdvertiserDashboardNewCampaign />} />
          <Route path="/advertiserDashboard/campaignDetails" element={<AdvertiserCampaignDetails />} />
        </Route>
      }
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
}

export function Effect({ effect }) {
  useEffect(() => effect?.(), [effect]);
  return null;
}

