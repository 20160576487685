import React, { useState, useEffect } from 'react'
import './FileUpload.css';
import RingLoader from "react-spinners/RingLoader";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { checkEmptyObj } from '../../../services/checkEmptyObj';
import { createBusiness } from '../../../services/createBusiness';
import { uploadFile } from '../../../services/uploadFile';
import { useSelector } from 'react-redux';
import { getToken } from '../../../features/token/tokenSaver';
import secureLocalStorage from 'react-secure-storage';
export default function FileUpload() {
    const [fileData, setFileData] = useState({ taxReturn: '', businessCOI: '', desiredLocation: '' });
    const [uploadedNames, setUploadedNames] = useState({ taxReturn: '', businessCOI: '', desiredLocation: '' });
    const [error, setErrMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();
    const [validForm, setValidForm] = useState('disabled');
    let token = secureLocalStorage.getItem("tk");
    const navigate = useNavigate();
    useEffect(() => {
        if (!token) {
            token = secureLocalStorage.getItem("tk")
        }
        if (state) {
            if (!state.phoneNumber) {
                navigate('/')
            }
        }
        else {
            navigate('/')

        }
        return () => {

        }
    }, []);
    function onChange(e, name) {
        // clean up earliest items
        var myFiles = {}
        // set state of files to false until each of them is processed
        var isFilesReady = false

        var files = document.querySelector("#" + e.target.id).files;
        if (files[0].size > 3145728) {
            alert("This file is too large, please upload one smaller than 3MB");
            return;
        }
        const filePromises = Object.entries(files).map(item => {
            return new Promise((resolve, reject) => {
                const [index, file] = item
                const reader = new FileReader();
                reader.readAsBinaryString(file);

                reader.onload = function (event) {
                    // handle reader success
                    myFiles['picture'] = btoa(event.target.result);
                    const newFileDataObj = { ...fileData, [name]: btoa(event.target.result) };
                    setFileData(newFileDataObj);
                    var fixedfname = file.name.replace(/\.[^/.]+$/, "");
                    var fileType = file.name.split(/(\\|\/)/g).pop().split('.')[file.name.split(/(\\|\/)/g).pop().split('.').length - 1];
                    setUploadedNames({ ...uploadedNames, [name]: { name: ((fixedfname.length > 30) ? fixedfname.slice(0, 30 - 1) + '...' : fixedfname), type: fileType } });

                    if (checkEmptyObj(newFileDataObj)) {
                        setValidForm('disabled')
                    }
                    else {
                        setValidForm('')

                    }
                    files = [];
                    resolve()
                };

                reader.onerror = function () {
                    console.log("couldn't read the file");
                    reject()
                };
            })
        })

        Promise.all(filePromises)
            .then(() => {
                // if each file processed successfuly then set our state to true
                isFilesReady = true
            })
            .catch((error) => {
                console.log(error)
                console.log('something wrong happened')
            })
    }
    function setError(err) {
        setErrMessage(err);
        var inputs = document.getElementsByTagName('input');
        for (var elem of inputs) {
            elem.value = '';
        }
    }
    function onSubmit(event) {
        if (checkEmptyObj(fileData)) {
            setError(true);
            setErrMessage("Please make sure your files are valid and try again");
            return;
        }
        setLoading(true);
        uploadFile({ phoneNumber: state.phoneNumber, cat: 0, file: fileData['taxReturn'] }, token).then(response => {
            if (response.data.success) {
                console.log("success 0")
                uploadFile({ phoneNumber: state.phoneNumber, cat: 1, file: fileData['businessCOI'] }, token).then(response => {
                   console.log(fileData)
                    if (response.data.success) {
                        console.log("success 1")

                        uploadFile({ phoneNumber: state.phoneNumber, cat: 2, file: fileData['desiredLocation'] }, token).then(response => {
                            if (response.data.success) {
                                console.log("success 2")

                                setLoading(false);
                                setError(false);
                                navigate('/user/todo', { state: { phoneNumber: state.phoneNumber.replace('+1', '') } })

                            }
                            else {
                                setLoading(false);
                                setError(true);
                                setUploadedNames({ taxReturn: '', businessCOI: '', desiredLocation: '' })
                                setFileData({ taxReturn: '', businessCOI: '', desiredLocation: '' })
                            }
                        }).catch(e => {
                            setLoading(false);
                            setError(true);
                            setFileData({ taxReturn: '', businessCOI: '', desiredLocation: '' })
                            setUploadedNames({ taxReturn: '', businessCOI: '', desiredLocation: '' })
                        })
                    }
                    else {
                        setLoading(false);
                        setError(true);
                        setFileData({ taxReturn: '', businessCOI: '', desiredLocation: '' })
                        setUploadedNames({ taxReturn: '', businessCOI: '', desiredLocation: '' })

                    }
                }).catch(e => {
                    console.log(e)
                    setLoading(false);
                    setError(true);
                    setFileData({ taxReturn: '', businessCOI: '', desiredLocation: '' })
                    setUploadedNames({ taxReturn: '', businessCOI: '', desiredLocation: '' })
                })
            }
            else {
                setLoading(false);
                setError(true);
                setFileData({ taxReturn: '', businessCOI: '', desiredLocation: '' })
                setUploadedNames({ taxReturn: '', businessCOI: '', desiredLocation: '' })

            }
        }).catch(e => {
            console.log(e)
            setLoading(false);
            setError(true);
            setFileData({ taxReturn: '', businessCOI: '', desiredLocation: '' })
            setUploadedNames({ taxReturn: '', businessCOI: '', desiredLocation: '' })
        })
    }
    return (
        <div>
            <div className='nav'>
                <div className='todoPageNavTitle' onClick={() => { navigate('/todo', { state: { phoneNumber: state.phoneNumber.replace('+1', '') } }) }}>INVERT</div>
            </div>
            <div className='businessDetailsContainer'>
                <span className='businessDetailsTitle'>Document Upload</span>
                <div className='uploadOptionsContainer'>
                    <div className='fileUploaderContainer'>
                        <div className='titleandfilenamecontainer'>
                            <span className='fileUploaderContainerTitle'>Owner's tax return with the Schedule C included (PDF)</span>
                            <label className='fileNameOnUpload'>{uploadedNames.taxReturn && uploadedNames.taxReturn.name + '.' + uploadedNames.taxReturn.type}</label>
                        </div>
                        <input onChange={(e) => {
                            onChange(e, 'taxReturn');
                        }} type={"file"} id='taxReturnInput' accept='application/pdf' hidden />
                        <label htmlFor='taxReturnInput' className='fileUploaderContainerButton'>Upload</label>
                    </div>
                    <div className='fileUploaderContainer'>
                        <div className='titleandfilenamecontainer'>
                            <span className='fileUploaderContainerTitle'>Business Certificate Of Insurance (PDF)</span>
                            <label className='fileNameOnUpload'>{uploadedNames.businessCOI && uploadedNames.businessCOI.name + '.' + uploadedNames.businessCOI.type}</label>
                        </div>
                        <input onChange={(e) => {
                            onChange(e, 'businessCOI');
                        }} type={"file"} id='businessCOIInput' accept='application/pdf' hidden />

                        <label htmlFor='businessCOIInput' className='fileUploaderContainerButton'>Upload</label>
                    </div> <div className='fileUploaderContainer'>
                        <div className='titleandfilenamecontainer'>

                            <span className='fileUploaderContainerTitle'>Image of Desired Screen Location</span>
                            <label className='fileNameOnUpload'>{uploadedNames.desiredLocation && uploadedNames.desiredLocation.name + '.' + uploadedNames.desiredLocation.type}</label>

                        </div>
                        <input onChange={(e) => {
                            onChange(e, 'desiredLocation');
                        }} type={"file"} accept='image/png' id='desiredLocationInput' hidden />
                        <label htmlFor='desiredLocationInput' className='fileUploaderContainerButton'>Upload</label>
                    </div>
                    <button onClick={(e) => { onSubmit(e) }} value={"Save"} className={`businessDetailsSave ${validForm}`} >
                        {!loading && "Submit"}
                        {loading && <RingLoader
                            color={"#ffffff"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />}
                    </button>
                    {error && <span className='errorPhoneVerification'>There was an error submitting your files, please try again later.</span>}

                </div>
            </div>
        </div>
    )
}