import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getCampaignDetails } from '../../../../../services/getCampaignDetails';
import { useSelector } from 'react-redux'
import { getToken } from '../../../../../features/token/tokenSaver';
import './AdvertiserCampaignDetails.css'
import AdvertiserCampaignDetailsLineGraph from './AdvertiserCampaignDetailsLineGraph/AdvetiserCampaignDetailsLineGraph';
import { toggleAdvertismentAdvertiser } from '../../../../../services/toggleAdvertismentActiveAdvertiser';
import ToggleButton from 'react-toggle-button'
import { deleteAdvertisementCampaign } from '../../../../../services/deleteAdvertismentCampaign';

export default function AdvertiserCampaignDetails() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const token = useSelector(getToken)
    const [adData, setAdData] = useState({});
    const [adLogData, setAdLogData] = useState([]);
    const [locations, setLocations] = useState([]);
    const [percentage, setPercentage] = useState(0);
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const [adDataDesiredTime, setAdDataDesiredTime] = useState('');
    function loadCampaignDetails() {
        getCampaignDetails(token, state?.adId).then(response => {
            setAdData(response.data?.advertismentData);
            setAdLogData(response.data?.adLogData);
            setLocations(response.data?.locations);
            setAdDataDesiredTime(convertToFormattedLocalTime(response.data?.advertismentData.DesiredTime));
            setPercentage(response.data?.percentage.Percentage);
        })
    }
    useEffect(() => {

        loadCampaignDetails();

    }, []);
    function convertToFormattedLocalTime(utcDateTimeString) {
        // Create a Date object from the UTC datetime string
        const date = new Date(utcDateTimeString);

        // Get the user's local time
        const localTime = date.toLocaleTimeString(undefined, {
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        console.log(localTime)
        return localTime;
    }

    

    if (!state?.adId) {
        navigate(-1)
    }
    function toggleAdvertisementStatus(newStatus) {
        let formatted = '';
        if (newStatus) {
            formatted = '1'
        }
        else {
            formatted = '2'
        }
        toggleAdvertismentAdvertiser(token, adData?.AdvertisementId, formatted).then(response => {
            loadCampaignDetails();

        })
    }
    function deleteAdvertisement() {
        var confirmed = window.confirm(`Delete Advertisment #${adData.AdvertisementId}?`);
        if (!confirmed) {
            return;
        }
        deleteAdvertisementCampaign(token, adData.AdvertisementId).then(response => {
            navigate('/advertiserDashboard/campaigns');
        })
    }
    return (
        <div className='advertiserDashboardContainerCampaigns'>
            <div className='adminDashboardScreenPageContainer'>
                <span className='advertismentCampaignDetailsTitle'>{adData.AdvertisementName}- #{adData.AdvertisementId}</span>
                <div className='advertismentDetailsContentAndImpressionsContainer'>
                    <div className='advertismentCampaignDetailsHalfContainer'>
                        {/* Commented for the time being until checkout ads are implemented */}
                        {/* <span className='advertismentCampaignDetailsSubTitle'>Content- {(adData.Storefront && "Storefront") || "Checkout"} Ad</span> */}
                        <span className='advertismentCampaignDetailsSubTitle'>Content- Storefront Ad</span>
                        <img className='advertismentImage' src={adData.AdContentHorizontal} />
                        <img className='advertismentImage' src={adData.AdContentVertical} />
                    </div>
                    <div className='advertismentCampaignDetailsHalfContainer'>
                        <span className='advertismentCampaignDetailsSubTitle'>Lifetime Impressions</span>
                        <AdvertiserCampaignDetailsLineGraph passedData={adLogData}></AdvertiserCampaignDetailsLineGraph>
                    </div>
                </div>

                <div className='advertismentCampaignDetailsFullContainer'>
                    <span className='advertismentCampaignDetailsSubTitle'>Stats</span>
                    <div className='advertismentStatsInnerContainer'>
                        <span className='advertismentCampaignDataTitle'>Desired Viewing Time: {adDataDesiredTime}</span>
                        <span className='advertismentCampaignDataTitle'>Desired Location(s): {locations.map(location => { return (<span className='advertiserCampaignDetailsLocationsContainers'>{location.City + ', ' + location.State}</span>) })}</span>
                        <span className='advertismentCampaignDataTitle'>Percentage of Impressions Within 30 Mins of Desired Time: {percentage.toFixed(1)}%</span>
                    </div>
                </div>
                <div className='advertismentCampaignDetailsFullContainer'>
                    <span className='advertismentCampaignDetailsSubTitle'>Pricing</span>
                    <div className='advertismentStatsInnerContainer'>
                        <span className='advertismentCampaignDataTitle'>Cost Per Impression: {formatter.format(adData.CostPerImpression)}</span>
                        <span className='advertismentCampaignDataTitle'>Budget: {formatter.format(adData.CurrentBudget)}</span>
                        <span className='advertismentCampaignDataTitle'>Impressions Purchased: {adData.Impressions}</span>
                        <span className='advertismentCampaignDataTitle'>Impressions Remaining: {adData.ImpressionsRemaining}</span>
                    </div>
                </div>
                <div className='advertismentCampaignDetailsFullContainer'>
                    <span className='advertismentCampaignDetailsSubTitle'>Take Action</span>
                    <div className='advertismentStatsInnerContainer'>
                        <span className='advertismentCampaignDataTitle'>Ad Status: {["Enabled", "Disabled", "Pending"][adData.StatusId - 1]} {adData.StatusId != 3 &&
                            <ToggleButton className="toggleBtn"
                                value={adData.StatusId == 1}
                                onToggle={(value) => {
                                    toggleAdvertisementStatus(!value);
                                }} />}</span>

                    </div>
                    <button className='denyBtnCampaignDetails userDetailsBtn' onClick={() => { deleteAdvertisement() }}>
                        Delete Campaign</button>
                </div>
            </div></div>
    )
}