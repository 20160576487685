import React, { useState, useEffect } from 'react'
import './Registration.css';
import useWindowDimensions from '../../../services/useWindowDimensions';
import { useNavigate } from 'react-router-dom';
import { checkEmptyObj } from '../../../services/checkEmptyObj';
import { useLocation } from 'react-router-dom';
import chevronIcon from '../../../assets/images/chevron.png'
export default function Registration() {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userData, setUserData] = useState({fname: "", lname: "", email: "", city: "", phoneNumber: phoneNumber});
    const [error, setError] = useState(false);
    const [mobileNav, setMobileNav] = useState(false);
    const { width } = useWindowDimensions();
    const { state } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (!state) {
            navigate("/");
        }
        else {
            if (state.phoneNumber) {
                setPhoneNumber(state.phoneNumber);
                setUserData({userData, phoneNumber: state.phoneNumber})
            }
            else {
                navigate("/")
            }
        }
    
     
    }, [])
    
    function onChange(propertyName, newValue) {
        setUserData({ ...userData, [propertyName]: newValue });
    }

    function onSubmit(event) {
        event.preventDefault();
        if (checkEmptyObj(userData) && userData.city.length > 50 && userData.fname.length > 50 && userData.lname.length > 50 && userData.email.length > 100) {
            setError(true);
            return;
        }
        else {
        
            navigate("/userVerification", { state: { user: userData, toRegister:true}});
        }
    }
    return (
        <div>
            {width > 706 &&
                <div className='nav'>
                    <div className='contactUs' onClick={() => { navigate('/ContactUs') }}>Contact Us</div>
                    <div className='title' onClick={() => navigate("/")}>INVERT</div>
                    <div className="placeholder" onClick={() => navigate("/userLogin")}>Login</div>
                </div>
            }
            {width <= 706 &&

                <div className='mobilenav'>
                    <div className=' nav'>
                        <div className='title mobilenavtitle' onClick={() => navigate("/")}>INVERT</div>
                        <div className='chevron mobilenavchevron' onClick={() => setMobileNav(!mobileNav)}><img className='chevronIconnav' src={chevronIcon} /></div>
                    </div>
                    <div className='mobilenavmenu' style={{ display: mobileNav && 'block' || 'none' }}>
                        <div className='mobilenavmenuItem'>
                            <span className='mobilenavmenuItemTitle' onClick={() => { navigate('/ContactUs') }}>Contact Us</span>
                        </div>
                        <div className='mobilenavmenuItem' onClick={() => navigate("/userLogin")}>
                            <span className='mobilenavmenuItemTitle' >Login</span>
                        </div>
                    </div>
                </div>
            }
            <form onSubmit={onSubmit} className='registrationSecondContainer'>
                <span required className='registrationSecondTitle'>Register To Advertise</span>
                <input value={userData.fname} maxLength={50} onChange={(e)=>{onChange("fname",e.nativeEvent.target.value)}} required className='registrationSecondInput' type="text" placeholder="First Name" />
                <input value={userData.lname} maxLength={50} onChange={(e) => { onChange("lname", e.nativeEvent.target.value) }} required className='registrationSecondInput' type="text" placeholder="Last Name" />
                <input value={userData.email} maxLength={100} onChange={(e) => { onChange("email", e.nativeEvent.target.value) }} required className='registrationSecondInput' type="email" placeholder="Email Address" />
                <input value={userData.city} maxLength={50} onChange={(e) => { onChange("city", e.nativeEvent.target.value) }} required className='registrationSecondInput' type="text" placeholder="City You're Located In"/>
                {error && <span className='errorPhoneVerification'>Please fill in all the fields</span>}

                <input type={"submit"} value={"Apply"} className='registerSecondApplyBtn' />

            </form>

        </div>
    )
}
