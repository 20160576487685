import React, { useState, useEffect } from 'react'
import './ZelleDetails.css';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { checkEmptyObj } from '../../../services/checkEmptyObj';
import PhoneInput from 'react-phone-number-input/input';
import { saveZelleDetails } from '../../../services/saveZelleDetails';
import { sendUnderReviewEmail } from '../../../services/sendUnderReviewEmail';
import { useSelector } from 'react-redux';
import { getToken } from '../../../features/token/tokenSaver';
import secureLocalStorage from 'react-secure-storage';
export default function ZelleDetails() {
    const [zellePhoneNumber, setZellePhoneNumber] = useState("");
    const [zelleData, setZelleData] = useState({zelleEmail:""});
    const [error, setError] = useState(false);
    const { state } = useLocation();
    let token = secureLocalStorage.getItem("tk");
    const [validForm, setValidForm] = useState('disabled');
    const navigate = useNavigate();
    useEffect(() => {
        if (!token) {
            token = secureLocalStorage.getItem("tk")
        }
        if (state) {
            if (!state.phoneNumber) {
                navigate('/')
            }
            if (!state.email) {
                navigate('/')
            }
            if (!state.fname) {
                navigate('/')
            }
        }
        else {
            navigate('/')

        }
        return () => {

        }
    }, []);
    function onChange(propertyName, newValue) {
        setZelleData({ ...zelleData, [propertyName]: newValue });
        if (checkEmptyObj({ ...zelleData, [propertyName]: newValue })) {
            setValidForm('disabled');

        }
        else {
            setValidForm('')

        }
    }
    function onSubmit(event) {
        event.preventDefault();
        saveZelleDetails({ zelleEmail: zelleData.zelleEmail, zellePhoneNumber, phoneNumber: state.phoneNumber }, token).then(response => {
            if (response.data.success) {
                sendUnderReviewEmail(state.email, state.fname, token);
                navigate("/user/todo", { state: { phoneNumber: state.phoneNumber.replace('+1', '') } })

            }
            else {
                setError(true)
            }
        }).catch(e => {
            console.error(e)
        })
    }
    return (
        <div>
            <div className='nav'>
                <div className='todoPageNavTitle'>INVERT</div>
            </div>
            <form onSubmit={onSubmit}>
            <div className='businessDetailsContainer'>
                <span className='businessDetailsTitle'>Zelle Details</span>
                <span className='businessDetailsSubTitle'>Enter your zelle information so we can deposit payments into your account</span>
                <div className='businessInfoTopInputContainer'>

                <label className='labelForBusinessInput'>Zelle Phone Number</label>

                <PhoneInput required 
            className='businessInfoNameInput'
            country="US"
            defaultCountry="US"
            national={"false"} value={zellePhoneNumber} onChange={setZellePhoneNumber}  type="text" placeholder="Phone Number" />
      
                </div>
                <div className='businessInfoTopInputContainer zelleEmailInput'>

<label className='labelForBusinessInput'>Zelle Email</label>

    <input required maxLength={50} value={zelleData.zelleEmail} onChange={(e) => { onChange("zelleEmail", e.nativeEvent.target.value) }} className='businessInfoNameInput ' type="email" placeholder="Email" />
</div>
                    <input type={"submit"} value={"Save"} className={`businessDetailsSave ${validForm}`}/>
                    {error && <span className='errorPhoneVerification'>There was an error submitting your details, please try again later.</span>}
                </div>

            </form>
        </div>
    )
}
