import React, { useEffect, useState } from 'react'
import './AdvertiserDashboardNewCampaign.css';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { getToken } from '../../../../features/token/tokenSaver';
import { getAdvertiserData } from '../../../../services/getAdvertiserData';
import { getAdvertiserCampaigns } from '../../../../services/getAdvertiserCampaigns';
import Select from 'react-select'
import { getScreenLocations } from '../../../../services/getScreenLocations';
import PaymentButton from './PaymentButton/PaymentButton';
import { RingLoader } from 'react-spinners';

export default function AdvertiserDashboardNewCampaign(props) {
    const navigate = useNavigate();
    const token = useSelector(getToken);
    const [campaignName, setCampaignName] = useState("");
    const [campaignViewingTime, setCampaignViewingTime] = useState({currentTimezone: "", utcTime:""});
    const [campaignBudget, setCampaignBudget] = useState("$10.00");
    const [campaignHorizontalContent, setCampaignHorizontalContent] = useState("");
    const [campaignVerticalContent, setCampaignVerticalContent] = useState("");
    const [totalCampaignImpressions, setTotalCampaignImpressions] = useState("1");
    const [costPerImpression, setCostPerImpression] = useState("$10.00");
    const [allLocations, setAllLocations] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedScreenType, setSelectedScreenType] = useState({ value: 1, label: "Storefront Window" });
    const [alertActive, setAlertActive] = useState(false);
    const [minCPI, setMinCPI] = useState(.03);
    const [minImpressions, setMinImpressions] = useState(1);
    const [valuesChecked, setValuesChecked] = useState(false);
    const [pendingRedirect, setPendingRedirect] = useState(false);
    const [currentTimezone, setCurrentTimezone] = useState(null)
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    function parsed() {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    }
    function getAllLocations() {
        getScreenLocations(token).then(result => {
            let options = [];
            result.data.data.forEach(location => {
                options.push({label: location.City +","+ location.State, value: location.LocationId})
            });
            setAllLocations(options);
        }).catch(e => {
            setAllLocations([]);
        })
    }
    function campaignNameOnChange(alteredValue) {
        setCampaignName(alteredValue);
    }
    function campaignViewingTimeOnChange(alteredValue) {
        var matches = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(alteredValue);
        if (matches) {
            const newCampaignViewingTime = { currentTimezone: alteredValue, utcTime: convertToUTC(alteredValue) };
            setCampaignViewingTime(newCampaignViewingTime);
        }
    }
    function campaignImpressionsOnBlur(alteredValue) {
        var impressions = parseFloat(alteredValue);
        if (impressions < minImpressions) {
            impressions = minImpressions;
        }
         if (impressions) {
            setTotalCampaignImpressions(impressions.toString())
     
        }
        // calculateBudget(costPerImpression, impressions);
        calculateCPI(campaignBudget, impressions);

    }
    function calculateBudget(costPerImpression, totalCampaignImpressions) {
        
        if (totalCampaignImpressions && costPerImpression) {
            var impressions = parseFloat(totalCampaignImpressions.toString());
            var formatted = costPerImpression.toString().replace(/\$/g, "");
            var formatted = formatted.replace(/\,/g, "");
            if (formatted.length === 0) {
                formatted = "0"
            }
            var cpi = parseFloat(formatted);
            var budget = formatter.format(cpi * impressions);
            if (budget) {
                setCampaignBudget(budget);
            }
        }
    }
    function calculateCPI(totalBudget, totalCampaignImpressions) {

        if (totalCampaignImpressions && totalBudget) {
            var impressions = parseFloat(totalCampaignImpressions.toString());
            var formatted = totalBudget.toString().replace(/\$/g, "");
            formatted = formatted.replace(/\,/g, "");
            if (formatted.length === 0) {
                formatted = "0"
            }
            var totalBudget = parseFloat(formatted);
            var cpiRaw = totalBudget / impressions;
            var cpi = formatter.format(cpiRaw);
            if (cpiRaw < minCPI) {
                calculateBudget(costPerImpression, impressions);
            }
            else {
                setCostPerImpression(cpi);

            }
        }
    }

    function campaignImpressionsOnChange(alteredValue) {
        var impressions = parseFloat(alteredValue);
        setValuesChecked(false);

        if (impressions) {
            setTotalCampaignImpressions(impressions.toString())
        }
        else {
            setTotalCampaignImpressions("")

        }
        // calculateBudget(costPerImpression, impressions);
    }
    function campaignBudgetOnBlur(alteredValue) {
        var formatted = alteredValue.replace("$", "");
        formatted = formatted.replace(/\,/g, "")
        var budget = parseFloat(formatted);

        if (formatted.length == 0) {
            budget = 10;
        }
        if (budget !== NaN || budget !== null || budget !== undefined) {
            if (budget < 10) {
                budget = 10;
            }
            setCampaignBudget(formatter.format(budget.toString()))
        }
        calculateCPI(budget, totalCampaignImpressions);

    }
    function cpiOnChange(alteredValue) {
        setValuesChecked(false);
        var formatted = alteredValue.replace("$", "");
        formatted = formatted.replace(/\,/g, "")
        var budget = parseFloat(formatted);

        if (budget || budget == 0) {
            setCostPerImpression("$"+formatted.toString());
        }
        else {
            setCostPerImpression("$")

        }
        
        // calculateBudget(budget, totalCampaignImpressions);
    }
    function calculateImpressions(budget, CPI) {
        var rawNewImpressions = budget/CPI;
        if (rawNewImpressions && rawNewImpressions >= minImpressions) {
            setTotalCampaignImpressions(Math.trunc(rawNewImpressions).toString())
        }
        else {
            setTotalCampaignImpressions(minImpressions.toString())

        }
    }
    function cpiOnBlur(alteredValue) {
        var formatted = alteredValue.replace("$", "");
        formatted = formatted.replace(/\,/g, "");
        var formattedBudget = campaignBudget.replace("$", "");
        formattedBudget = formattedBudget.replace(/\,/g, "");
        formattedBudget = parseFloat(formattedBudget);
        var cpi = parseFloat(formatted);
        
        if (formatted.length == 0) {
            cpi = minCPI;
        }
        if (cpi !== NaN && cpi !== null && cpi !== undefined && (cpi * parseFloat(totalCampaignImpressions)) < formattedBudget) {
            if (cpi < minCPI) {
                cpi = minCPI;
            }
            setCostPerImpression(formatter.format(cpi.toString()));
        }
        else {
            calculateCPI(campaignBudget, totalCampaignImpressions);
        }
        calculateImpressions(formattedBudget,formatted)
        // calculateBudget(budget, totalCampaignImpressions);

    }
    function campaignBudgetOnChange(alteredValue) {
        setValuesChecked(false)
        var formatted = alteredValue.replace("$", "");
        formatted = formatted.replace(/\,/g, "");
        var budget = parseFloat(formatted);
        setCampaignBudget(alteredValue);
        // if (budget) {
        //     setCampaignBudget("$"+ formatted.toString());
        // }
        // else {
        //     setCampaignBudget("$")

        // }
        // calculateCPI(budget, totalCampaignImpressions);

    }
    function onVerticalFileUploadChange(e) {
        // clean up earliest items
        var myFiles = {}
        // set state of files to false until each of them is processed
        var isFilesReady = false

        var files = document.querySelector("#" + e.target.id).files;
        if (files[0].size > 3145728) {
            alert("This file is too large, please upload content smaller than 3MB");
            return;
        }
        var reader = new FileReader();
        var error = false;
        //Read the contents of Image File.
        reader.readAsDataURL(files[0]);
        reader.onload = function (te) {

            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = te.target.result;

            //Validate the File Height and Width.
            image.onload = function () {
                var ratio = Math.round((this.width/this.height) * 100) / 100;
                if (ratio !== .56) {
                    alert("File aspect ratio must be 9:16");
                    document.querySelector("#" + e.target.id).value = "";

                    error = true;
                    return false;
                }
            };
        };
        if (error)
            return;
        if (files[0].name.split(".")[files[0].name.split(".").length - 1] === "jpg" || files[0].name.split(".")[files[0].name.split(".").length - 1] === "png" || files[0].name.split(".")[files[0].name.split(".").length - 1] === "mp4" || files[0].name.split(".")[files[0].name.split(".").length - 1] === "mov") {

        }
        else {
            alert("Please upload a file with the file type JPG, PNG, MP4, or MOV");
            document.querySelector("#" + e.target.id).value = ""
            return;
        }
        const filePromises = Object.entries(files).map(item => {
            return new Promise((resolve, reject) => {
                const [index, file] = item
                const reader = new FileReader();
                reader.readAsBinaryString(file);

                reader.onload = function (event) {
                    // handle reader success
                    myFiles['picture'] = btoa(event.target.result);
                    const newFileDataObj = btoa(event.target.result);
                    setCampaignVerticalContent(newFileDataObj);
                    files = [];
                    resolve()
                };

                reader.onerror = function () {
                    reject()
                };
            })
        })

        Promise.all(filePromises)
            .then(() => {
                // if each file processed successfuly then set our state to true
                isFilesReady = true
            })
            .catch((error) => {
                console.log(error)
            })
    }
    function onHorizontalFileUploadChange(e) {
        // clean up earliest items
        var myFiles = {}
        // set state of files to false until each of them is processed
        var isFilesReady = false

        var files = document.querySelector("#" + e.target.id).files;
        if (files[0].size > 3145728) {
            alert("This file is too large, please upload content smaller than 3MB");
            return;
        }
        var reader = new FileReader();
        var error = false;
        //Read the contents of Image File.
        reader.readAsDataURL(files[0]);
        reader.onload = function (te) {

            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = te.target.result;

            //Validate the File Height and Width.
            image.onload = function () {
                var ratio = Math.round((this.width/this.height) * 100)/ 100;
                if (ratio !== 1.78) {
                    alert("File aspect ratio must be 16:9");
                    document.querySelector("#" + e.target.id).value = "";
                    error = true;
                    return false;
                }
            };
        };
        if (error)
            return;
        if (files[0].name.split(".")[files[0].name.split(".").length - 1] === "jpg" || files[0].name.split(".")[files[0].name.split(".").length - 1] === "png" || files[0].name.split(".")[files[0].name.split(".").length - 1] === "mp4" || files[0].name.split(".")[files[0].name.split(".").length - 1]=== "mov") {
          
        }
        else {
            alert("Please upload a file with the file type JPG, PNG, MP4, or MOV");
            document.querySelector("#" + e.target.id).value = ""
            return;
        }
        const filePromises = Object.entries(files).map(item => {
            return new Promise((resolve, reject) => {
                const [index, file] = item
                const reader = new FileReader();
                reader.readAsBinaryString(file);

                reader.onload = function (event) {
                    // handle reader success
                    myFiles['picture'] = btoa(event.target.result);
                    const newFileDataObj = btoa(event.target.result);
                    setCampaignHorizontalContent(newFileDataObj);
                    files = [];
                    resolve()
                };

                reader.onerror = function () {
                    reject()
                };
            })
        })

        Promise.all(filePromises)
            .then(() => {
                // if each file processed successfuly then set our state to true
                isFilesReady = true
            })
            .catch((error) => {
                console.log(error)
            })
    }
    function handleScreenTypeChoose(e) {
        setSelectedScreenType(e);
        switch (e.value) {
            case 1:
                setMinCPI(.03);
                setMinImpressions(1);
                break;
            case 0:
                setMinCPI(.01);
                setMinImpressions(1);
                break;
        }
    }
    function checkValues() {
        setValuesChecked(true);
    }
    function onSubmit(e) {
        e.preventDefault();
        convertToUTC(campaignViewingTime.currentTimezone);
        setAlertActive(true);
    }
    function getCurrentTimezone() {
        // Get the current date and time in the local timezone
        const currentDate = new Date();

        // Get the timezone from the Intl.DateTimeFormat object
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        setCurrentTimezone(timezone);
    }
    function convertToUTC(timeString) {
        // Create a date object from the input time string
        const date = new Date(`2000-01-01T${timeString}:00-08:00`);

        // Get the time in UTC
        const utcTime = date.toISOString().substr(11, 8);
        return utcTime;
    }

    useEffect(() => {
        campaignImpressionsOnBlur(totalCampaignImpressions)
        cpiOnBlur(costPerImpression)

    }, [minCPI, minImpressions])
    useEffect(() => {
        getAllLocations();
        getCurrentTimezone();
        return () => {

        }
    }, [token]);

    return (

        <div className={`advertiserDashboardContainerCampaigns`}>
            
            {alertActive && <div className='alert'>
                <span className='registeredScreensTitle advertiserListTitle'>Payment</span>
                {pendingRedirect &&
                    <div className="loadingOverlay">
                        <RingLoader
                            color={"#ffffff"}
                            loading={true}
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                            className='loaderForOverlay'
                        />
                    </div>
                }
                <PaymentButton setPendingRedirect={setPendingRedirect} navigate={navigate} className="paymentButton" data={{ campaignName, campaignViewingTime: campaignViewingTime.utcTime, campaignBudget, campaignHorizontalContent, campaignVerticalContent, totalCampaignImpressions, costPerImpression, selectedLocations, storefront: selectedScreenType.value }} cost={parseFloat(campaignBudget.toString().replace(/\$/g, "").replace(/\,/g, "").replace(/\./g, ","))}></PaymentButton>

                <input onClick={() => { setAlertActive(false) }} className='advertiserRegisterSubmitBtn paymentCancelButton' value='Cancel' />

            </div>
            }
            {alertActive && <div className='opaqueBackground '></div>}
            <div className={`adminDashboardScreenPageContainer `}>
                <span className='registeredScreensTitle advertiserListTitle'>New Campaign</span>
                <form onSubmit={(e)=>{onSubmit(e)}}>
                <div className='newCampaignInputContainer'>
                    <span className='newCampaignInputTitle'>Campaign Name</span>
                        <input value={campaignName} onChange={(e) => { campaignNameOnChange(e.nativeEvent.target.value)}} required type={"text"} maxLength={50} placeholder='Campaign Name' className='newCampaignInputInputFull'/>
                </div>
                        
                <div className='newCampaignInputTwoContainer'>
                    <span className='newCampaignInputTitle'>Desired Viewing Time({currentTimezone ? currentTimezone: "UTC"} Timezone)</span>
                            <input value={campaignViewingTime.currentTimezone} onChange={(e => { campaignViewingTimeOnChange(e.nativeEvent.target.value)})} required type={"time"} maxLength={50} placeholder='Desired Viewing Time' className='newCampaignInputInputFull'/>
                        </div>
                        <div className='newCampaignInputTwoContainer'>
                            <span className='newCampaignInputTitle'>Desired Location</span>
                            <Select value={selectedLocations} onChange={setSelectedLocations} className="multiSelectNewCampaignInput" required isMulti options={allLocations} />
                        </div>
                        <div className='newCampaignInputTwoContainer'>
                            <span className='newCampaignInputTitle'>Screen Type</span>
                        <Select defaultValue={selectedScreenType} value={selectedScreenType} onChange={handleScreenTypeChoose} className="multiSelectNewCampaignInput" required isMulti={false} options={[{ value: 1, label: "Storefront Window" }]} />
                        {/* Commented until Checkouts Implemented */}
                        {/* <Select defaultValue={selectedScreenType} value={selectedScreenType} onChange={handleScreenTypeChoose} className="multiSelectNewCampaignInput" required isMulti={false} options={[{ value: 1, label: "Storefront Window" }, { value: 0, label: "Screen at Checkout" }]} /> */}
                        </div>
                
                        <div className='newCampaignInputTwoContainer'>
                            <span className='newCampaignInputTitle'>Horizontal Content(.png or .mp4, 16:9 Aspect Ratio)</span>
                            <input id='fileUploadNewCampaignHorizontal' onChange={(e) => { onHorizontalFileUploadChange(e) }} accept='image/*,video/mp4,video/x-m4v,video/*' required type={"file"} maxLength={50} placeholder='Content(File)' className='newCampaignInputInputFull newCampaignInputFileUpload' />
                    </div>
                    <div className='newCampaignInputTwoContainer'>
                        {selectedScreenType.value == 1 &&
                                <>
                            <span className='newCampaignInputTitle'>Vertical Content(.png or .mp4, 9:16 Aspect Ratio)</span>
                            <input id='fileUploadNewCampaignVertical' onChange={(e) => { onVerticalFileUploadChange(e) }} accept='image/*,video/mp4,video/x-m4v,video/*' required type={"file"} maxLength={50} placeholder='Content(File)' className='newCampaignInputInputFull newCampaignInputFileUpload' />
                        </>
                        }
                            </div>
                        <div className='newCampaignInputTwoContainer'>
                            <span className='newCampaignInputTitle'>Campaign Budget</span>
                            <input value={campaignBudget} onBlur={(e => { campaignBudgetOnBlur(e.nativeEvent.target.value) })} onChange={(e => { campaignBudgetOnChange(e.nativeEvent.target.value) })} required type={"text"} min={10} maxLength={50} placeholder='$' className='newCampaignInputInputFull' />
                        </div>
                        <div className='newCampaignInputTwoContainer'>
                            <span className='newCampaignInputTitle'>Total Impressions</span>
                            <input value={totalCampaignImpressions} required type={"text"} min={1} onBlur={(e => { campaignImpressionsOnBlur(e.nativeEvent.target.value) })} onChange={(e => { campaignImpressionsOnChange(e.nativeEvent.target.value) })} maxLength={50} placeholder='Total Impressions' className='newCampaignInputInputFull' />
                        </div>
                  
                

                    <div className='newCampaignInputTwoContainer'>
                        <span className='newCampaignInputTitle'>Cost Per Impression</span>
                        <input onBlur={(e => { cpiOnBlur(e.nativeEvent.target.value) })} onChange={(e => { cpiOnChange(e.nativeEvent.target.value) })} value={costPerImpression} required type={"text"} min={.10} maxLength={50} placeholder='Cost Per Impression' className='newCampaignInputInputFull' />
                        </div>
                    {!valuesChecked && <input onClick={() => {
                        checkValues();
}} className='advertiserRegisterSubmitBtn' value='Check Values' />
                    }
                    {valuesChecked && <input  type={"submit"} className='advertiserRegisterSubmitBtn' value='Deploy' />
                    }
               </form>
            </div>
        </div>
    )
}
