import React from 'react'
import './UserNav.css'
import { useNavigate } from 'react-router-dom';
import logoutImage from '../../../assets/images/logoutUser.png'
import secureLocalStorage from 'react-secure-storage';
export default function UserNav() {
    const navigate = useNavigate();
    function logout() {
        secureLocalStorage.removeItem("tk");
        secureLocalStorage.removeItem("refreshToken");
        navigate("/")
    }
  return (
      <div className='nav'>
          <div className='todoPageNavTitle' onClick={() => navigate("/")}>INVERT</div>
          <div className='todoPageNavLogoutContainer' onClick={() => { logout(); }}>
              <span className='todoNavLogoutText'>Logout</span><img className='todoPageNavLogout' src={logoutImage} alt='logout' />
          </div>
      </div>
  )
}
