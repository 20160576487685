import React, { useState, useEffect } from 'react'
import './Todo.css';

import { useNavigate } from 'react-router-dom';
import { getUserData } from '../../../services/getUserData';
import businessDetailsIcon from '../../../assets/images/businessDetailsIcon.svg';
import todoDownArrow from '../../../assets/images/todoDownArrow.png';
import displayChooseIcon from '../../../assets/images/displayChooseIcon.svg';
import zelleDetailsIcon from '../../../assets/images/zelleDetails.svg';
import documentUploadIcon from '../../../assets/images/documentUploadIcon.svg';
import waitIcon from '../../../assets/images/waitIcon.svg';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getToken } from '../../../features/token/tokenSaver';
import { RingLoader } from 'react-spinners';
import secureLocalStorage from 'react-secure-storage';
import UserNav from '../UserNav/UserNav';

export default function Todo() {
    const { state } = useLocation();

    const [userData, setUserData] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [pageLoading, setPageLoading] = useState(true);
    let token = state?.token;
    const navigate = useNavigate();
    useEffect(() => {
        if (!token) {
            token = secureLocalStorage.getItem("tk")
        }
        if (!state) {
                navigate("/");
        }
        else if (state.phoneNumber !== "" && state.phoneNumber !== undefined) {

                setPhoneNumber(state.phoneNumber);
                getUserData(state.phoneNumber, token).then(function (response) {
                    setUserData(response.data);
                    setPageLoading(false)

                    if (response.data.BusinessDetailsComplete && response.data.DocumentUploadComplete && response.data.ZelleComplete && response.data.DisplayLinkCommplete && response.data.ApplicantStatusId === 2) {
                        navigate("/user/userDashboard/", { state: { userId: response.data.UserId, phoneNumber: state.phoneNumber}})
                    }
                })
                    .catch(function (error) {
                    });
                var checks = 0;
                var checkUserData =  setInterval(async() => {
                 await   getUserData(state.phoneNumber, token).then(function (response) {
                        setUserData(response.data);
                        if (response.data != null) {
                            clearInterval(checkUserData);
                            setPageLoading(false)

                     }
                   
                 }).catch(function (error) {
                    
                 })
                    if (checks === 5) {
                        clearInterval(checkUserData);

                        navigate("/");

                    }
                    checks += 1;
                }, 1000)
            
            
        }
        else {
            setPageLoading(true)

        }
          
        
    }, []);
    function getCount() {
        var count = 0;
        if (!userData.BusinessDetailsComplete) {
            count++;
        }
        if (!userData.DocumentUploadComplete) {
            count++;
        }
        if (!userData.DisplayLinkCommplete) {
            count++;
        }
        if(!userData.ZelleComplete){
count++;
        }
        return count;
    }
    if (pageLoading) {
        return (
            <div>
                <div className='nav'>
                    <div className='todoPageNavTitle' onClick={() => navigate("/")}>INVERT</div>
                </div>
           <RingLoader
                color={"#c9c9c9"}
                loading={true}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
                className='todoPageLoader'
                />
                </div>
    )
}
    return (
        <div>
            <UserNav></UserNav>

            
            {userData !== undefined && userData !== null && typeof userData !== "string" && 
                <div className='todoContainer'>
                <div className='todoTitleContainer'>
                <span className='todoTitle'>To-do</span>
                        <div className='itemCounttodo'>{getCount()} ITEM{getCount()>1&&'S'}</div>
                    </div>
                    {!userData.BusinessDetailsComplete &&

                    <div className='notDoneItems'>
                            <div onClick={() => navigate("/user/businessDetails", { state: { "phoneNumber": phoneNumber } })} className='todoItem'>
                                <div className='todoItemItems'>
                                    <img alt='bizIcon' className='todoItemImage' src={businessDetailsIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle'>Business Details</span>
                                        <span className='todoItemSubTitle'>We need some information about your business</span>
                                    </div>
                                </div>
                            </div>
                            <div className='downArrowTodoContainer'>
                                <img alt='downArrowIcon' className='downArrowtodo' src={todoDownArrow} />
                                </div>
                            <div className='todoItem disabledtodoItem'>
                                <div className='todoItemItems'>
                                    <img alt='todo item disabled' className='todoItemImage disabledImgtodo' src={documentUploadIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle disabledTitle'>Upload Documents</span>
                                        <span className='todoItemSubTitle'>We need some documents to prove your business' legitimacy.</span>
                                    </div>
                                </div>
                            </div>
                            <div className='downArrowTodoContainer'>
                                <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
                                </div>
                            <div className='todoItem disabledtodoItem'>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage disabledImgtodo' src={zelleDetailsIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle disabledTitle'>Set Up Payments</span>
                                        <span className='todoItemSubTitle'>Enter your Zelle details to start accepting payments.</span>
                                    </div>
                                </div>
                            </div>
                            <div className='downArrowTodoContainer'>
                                <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
                                </div>
                            <div className='todoItem disabledtodoItem'>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage disabledImgtodo' src={waitIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle disabledTitle'>Application Under Review</span>
                                        <span className='todoItemSubTitle'>We're reviewing your application. Here's what to do until then.</span>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='downArrowTodoContainer'>

                            <img alt="todoImg" className='downArrowtodo'src={todoDownArrow} />
</div>
                            <div className='todoItem disabledtodoItem'>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage disabledImgtodo' src={displayChooseIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle disabledTitle'>Link Your Screen</span>
                                        <span className='todoItemSubTitle'>Link your screen to your account once it arrives.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {userData.BusinessDetailsComplete && !userData.DocumentUploadComplete &&

                        <div className='notDoneItems'>
                            <div className='todoItem disabledtodoItem'>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage disabledImgtodo' src={businessDetailsIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle disabledTitle'>Business Details</span>
                                        <span className='todoItemSubTitle'>We need some information about your business</span>
                                    </div>
                                </div>
                            </div>
                            <div className='downArrowTodoContainer'>
                                <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
                            </div>
                            <div onClick={() => navigate("/user/fileUpload", { state: { "phoneNumber": phoneNumber } })} className='todoItem'>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage' src={documentUploadIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle'>Upload Documents</span>
                                        <span className='todoItemSubTitle'>We need some documents to prove your business' legitimacy.</span>
                                    </div>
                                </div>
                            </div>
                            <div className='downArrowTodoContainer'>
                                <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
                                </div>
                            <div className='todoItem disabledtodoItem'>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage disabledImgtodo' src={zelleDetailsIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle disabledTitle'>Set Up Payments</span>
                                        <span className='todoItemSubTitle'>Enter your Zelle details to start accepting payments.</span>
                                    </div>
                                </div>
                            </div>
                            <div className='downArrowTodoContainer'>
                                <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
                                </div>
                            <div className='todoItem disabledtodoItem'>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage disabledImgtodo' src={waitIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle disabledTitle'>Application Under Review</span>
                                        <span className='todoItemSubTitle'>We're reviewing your application. Here's what to do until then.</span>
                                    </div>
                                </div>
                            </div>
                            <div className='downArrowTodoContainer'>

                                <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
                            </div>

                            <div className='todoItem disabledtodoItem'>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage disabledImgtodo' src={displayChooseIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle disabledTitle'>Link Your Screen</span>
                                        <span className='todoItemSubTitle'>Link your screen to your account once it arrives.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                     {userData.BusinessDetailsComplete && userData.DocumentUploadComplete && !userData.ZelleComplete &&

<div className='notDoneItems'>
    <div className='todoItem disabledtodoItem'>
        <div className='todoItemItems'>
            <img alt="todoImg" className='todoItemImage disabledImgtodo' src={businessDetailsIcon} />
            <div className='todoItemTitleContainer'>
                <span className='todoItemTitle disabledTitle'>Business Details</span>
                <span className='todoItemSubTitle'>We need some information about your business</span>
            </div>
        </div>
    </div>
    <div className='downArrowTodoContainer'>
        <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
    </div>
    <div className='todoItem disabledtodoItem'>
        <div className='todoItemItems'>
            <img alt="todoImg" className='todoItemImage disabledImgtodo' src={documentUploadIcon} />
            <div className='todoItemTitleContainer'>
                <span className='todoItemTitle disabledTitle'>Upload Documents</span>
                <span className='todoItemSubTitle'>We need some documents to prove your business' legitimacy.</span>
            </div>
        </div>
    </div>
    
    <div className='downArrowTodoContainer'>
                                <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
                                </div>
                            <div className='todoItem 'onClick={() => navigate("/user/zelleDetails", { state: { "phoneNumber": phoneNumber, "email": userData.Email, "fname": userData.FName } })}>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage zelleEnabled ' src={zelleDetailsIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle '>Set Up Payments</span>
                                        <span className='todoItemSubTitle'>Enter your Zelle details to start accepting payments.</span>
                                    </div>
                                </div>
                            </div>
                            <div className='downArrowTodoContainer'>
                            <div className='downArrowTodoContainer'>
                                <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
                                </div>
                            <div className='todoItem disabledtodoItem'>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage disabledImgtodo' src={waitIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle disabledTitle'>Application Under Review</span>
                                        <span className='todoItemSubTitle'>We're reviewing your application. Here's what to do until then.</span>
                                    </div>
                                </div>
                            </div>
<img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
</div>

<div className='todoItem disabledtodoItem'>
<div className='todoItemItems'>
    <img alt="todoImg" className='todoItemImage disabledImgtodo' src={displayChooseIcon} />
    <div className='todoItemTitleContainer'>
        <span className='todoItemTitle disabledTitle'>Link Your Screen</span>
        <span className='todoItemSubTitle'>Link your screen to your account once it arrives.</span>
    </div>
</div>
</div>
</div>
}
{userData.BusinessDetailsComplete && userData.DocumentUploadComplete && userData.ZelleComplete  && userData.ApplicantStatusId ==4 &&

<div className='notDoneItems'>
    <div className='todoItem disabledtodoItem'>
        <div className='todoItemItems'>
            <img alt="todoImg" className='todoItemImage disabledImgtodo' src={businessDetailsIcon} />
            <div className='todoItemTitleContainer'>
                <span className='todoItemTitle disabledTitle'>Business Details</span>
                <span className='todoItemSubTitle'>We need some information about your business</span>
            </div>
        </div>
    </div>
    <div className='downArrowTodoContainer'>
        <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
    </div>
    <div className='todoItem disabledtodoItem'>
        <div className='todoItemItems'>
            <img alt="todoImg" className='todoItemImage disabledImgtodo' src={documentUploadIcon} />
            <div className='todoItemTitleContainer'>
                <span className='todoItemTitle disabledTitle'>Upload Documents</span>
                <span className='todoItemSubTitle'>We need some documents to prove your business' legitimacy.</span>
            </div>
        </div>
    </div>
    <div className='downArrowTodoContainer'>
        <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
        </div>
    <div className='todoItem disabledtodoItem'>
        <div className='todoItemItems'>
            <img alt="todoImg" className='todoItemImage disabledImgtodo' src={zelleDetailsIcon} />
            <div className='todoItemTitleContainer'>
                <span className='todoItemTitle disabledTitle'>Set Up Payments</span>
                <span className='todoItemSubTitle'>Enter your Zelle details to start accepting payments.</span>
            </div>
        </div>
    </div>
      <div className='downArrowTodoContainer'>
        <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
        </div>
    <div className='todoItem' onClick={() => navigate("/user/applicationUnderReview", { state: { "phoneNumber": phoneNumber } })}>
        <div className='todoItemItems'>
            <img alt="todoImg" className='todoItemImage' src={waitIcon} />
            <div className='todoItemTitleContainer'>
                <span className='todoItemTitle'>Application Under Review</span>
                <span className='todoItemSubTitle'>We're reviewing your application. Here's what to do until then.</span>
            </div>
        </div>
    </div>
    <div className='downArrowTodoContainer'>

        <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
    </div>
    <div className='todoItem disabledtodoItem'>
<div className='todoItemItems'>
    <img alt="todoImg" className='todoItemImage disabledImgtodo' src={displayChooseIcon} />
    <div className='todoItemTitleContainer'>
        <span className='todoItemTitle disabledTitle'>Link Your Screen</span>
        <span className='todoItemSubTitle'>Link your screen to your account once it arrives.</span>
    </div>
</div>
</div>
</div>
}
                    {userData.BusinessDetailsComplete && userData.DocumentUploadComplete && userData.ZelleComplete && !userData.DisplayLinkCommplete && userData.ApplicantStatusId ==2 &&

                        <div className='notDoneItems'>
                            <div className='todoItem disabledtodoItem'>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage disabledImgtodo' src={businessDetailsIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle disabledTitle'>Business Details</span>
                                        <span className='todoItemSubTitle'>We need some information about your business</span>
                                    </div>
                                </div>
                            </div>
                            <div className='downArrowTodoContainer'>
                                <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
                            </div>
                            <div className='todoItem disabledtodoItem'>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage disabledImgtodo' src={documentUploadIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle disabledTitle'>Upload Documents</span>
                                        <span className='todoItemSubTitle'>We need some documents to prove your business' legitimacy.</span>
                                    </div>
                                </div>
                            </div>
                            <div className='downArrowTodoContainer'>
                                <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
                                </div>
                            <div className='todoItem disabledtodoItem'>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage disabledImgtodo' src={zelleDetailsIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle disabledTitle'>Set Up Payments</span>
                                        <span className='todoItemSubTitle'>Enter your Zelle details to start accepting payments.</span>
                                    </div>
                                </div>
                            </div>
                              <div className='downArrowTodoContainer'>
                                <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
                                </div>
                            <div className='todoItem disabledtodoItem'>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage disabledImgtodo' src={waitIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle disabledTitle'>Application Under Review</span>
                                        <span className='todoItemSubTitle'>We're reviewing your application. Here's what to do until then.</span>
                                    </div>
                                </div>
                            </div>
                            <div className='downArrowTodoContainer'>

                                <img alt="todoImg" className='downArrowtodo' src={todoDownArrow} />
                            </div>
                            <div onClick={() => navigate("/user/linkNewScreen", { state: { "phoneNumber": phoneNumber } })} className='todoItem'>
                                <div className='todoItemItems'>
                                    <img alt="todoImg" className='todoItemImage' src={displayChooseIcon} />
                                    <div className='todoItemTitleContainer'>
                                        <span className='todoItemTitle'>Link Your Screen</span>
                                        <span className='todoItemSubTitle'>Link your screen to your account once it arrives.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }

        </div>
    )
}
