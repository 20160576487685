import React,{useEffect} from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Label } from 'recharts';
import './ScreebActivityGuageChart.css';
const ScreenActivityGuageChart = (props) => {
    const data = [
        { name: 'Active Screens', value: parseInt(props.screensActive) },
        { name: 'Inactive Screens', value: parseInt(props.totalScreens) - parseInt(props.screensActive) },
    
    ];
    const COLORS = ['#CA3C0F', '#333333'];
    useEffect(() => {
        
     return () => {
        
      }
    }, [])
    
    return (
        <ResponsiveContainer width={"100%"} height={400}>
        <PieChart className='gaugeChart' >
            <Pie
                data={data}
                cx={"50%"}
                cy={200}
                innerRadius={100}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}

                    <Label fill='black' style={{ marginTop: 10 }} fontSize={40} value={`${(((parseInt(props.screensActive) / parseInt(props.totalScreens))) * 100).toFixed(1)}%`} position="centerBottom" />
                    <Label position={"centerTop"} display={12} fontSize={15} value="OF SCREENS ACTIVE" />
            </Pie>
        </PieChart>
        </ResponsiveContainer>
    );
};

export default ScreenActivityGuageChart;