import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../services/useWindowDimensions';
import chevronIcon from '../../assets/images/chevron.png';
import './ContactUs.css';
import Iframe from 'react-iframe'
import RingLoader from "react-spinners/RingLoader";

export default function ContactUs() {
    const navigate = useNavigate();
    const [mobileNav, setMobileNav] = useState(false);
    const { height, width } = useWindowDimensions();
    const [loading, setLoading] = useState(true);
   
    
    return (
      
        <div>
            
            {width > 706 &&
          <div className='nav'>
              <div className='contactUs' onClick={() => { navigate('/ContactUs') }}>Contact Us</div>
              <div className='title' onClick={() => navigate("/")}>INVERT</div>
              <div className="placeholder" onClick={() => navigate("/userLogin")}>Login</div>
          </div>
      }
          {width <= 706 &&

              <div className='mobilenav'>
                  <div className=' nav'>
                      <div className='title mobilenavtitle' onClick={() => navigate("/")}>INVERT</div>
                      <div className='chevron mobilenavchevron' onClick={() => setMobileNav(!mobileNav)}><img className='chevronIconnav' src={chevronIcon} /></div>
                  </div>
                  <div className='mobilenavmenu' style={{ display: mobileNav && 'block' || 'none' }}>
                      <div className='mobilenavmenuItem'>
                          <span className='mobilenavmenuItemTitle' onClick={() => { navigate('/ContactUs') }}>Contact Us</span>
                      </div>
                      <div className='mobilenavmenuItem' onClick={() => navigate("/userLogin")}>
                          <span className='mobilenavmenuItemTitle' >Login</span>
                      </div>
                  </div>
              </div>
          }
          <div className='contactUsPageContainer'>
              <div className={``}>
              <Iframe onLoad={()=>{setLoading(false)}} loading='eager' className='contactUsIframe' url="https://forms.monday.com/forms/embed/3a988ea50ec55dee31a268cce9202c10?r=use1"
                      width={`100%`} height={height-84}
                  styles={{border: 0}}
/>
              </div>
            </div>
            <div className='contactUsSpinnerContainer'>
                <RingLoader
                    color={"rgb(172, 172, 172)"}
                    loading={loading}
                    size={80}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
      </div>
      
  )
}
