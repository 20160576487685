import React, {useEffect, useState} from 'react'
import './AdvertiserDashboardHome.css'
import impressionsImage from '../../../../assets/images/impressionsImage.png';
import megaphoneImage from '../../../../assets/images/megaphoneImage.png';
import { getAdvertiserImpressionsAndCampaigns } from '../../../../services/getAdvertiserImpressionsCampaigns';
import { useSelector } from 'react-redux';
import { getToken } from '../../../../features/token/tokenSaver';
import exampleAdImage from '../../../../assets/images/exampleAdImage.png';
import DashboardLineGraph from './DashboardLineGraph/DashboardLineGraph';
import DashboardCircleGraph from './DashboardCircleGraph/DashboardCircleGraph';
import { useNavigate } from 'react-router-dom';
export default function AdvertiserDashboardHome() {
    var abbreviate = require('number-abbreviate')
    const [atAGlanceData, setAtAGlanceData] = useState({});
    const [impressionsToday, setImpressionsToday] = useState(0);
    const [allTimeBudget, setAllTimeBudget] = useState(0);
    const [budgetToday, setBudgetToday] = useState(0);
    const [topCampaigns, setTopCampaigns] = useState([]);
    const token = useSelector(getToken);
    const navigate = useNavigate();
    const [id, setAdId] = useState();
    useEffect(() => {
        function parsed() {
            try {
                return JSON.parse(atob(token.split('.')[1]));
            } catch (e) {
                return null;
            }
        }
        const decoded = parsed();
        if (decoded) {
            setAdId(decoded.id);
            getAdvertiserImpressionsAndCampaigns(token, decoded.id).then(response => {
                setAtAGlanceData(response.data.data);
                setImpressionsToday(parseInt(response.data.impressionsToday.impressionsToday));
                setAllTimeBudget(parseInt(response.data.allTimeBudget.allTimeBudget));
                setBudgetToday(parseInt(response.data.budgetToday.budgetToday));
                setTopCampaigns(response.data.campaigns);
            })
        }
    },[token])

  return (
      <div className='advertiserDashboardContainer'>
          <span className='advertiserDashboardHometitle'>Welcome!</span>
          <div className='atAGlanceItemsContainer'>
              <div className='atAGlanceImpressions'>
                  <span className='atAGlanceImpressionsTitle'>Total Impressions</span>
                  <span className='atAGlanceImpressionsCount'>{abbreviate(atAGlanceData.impressions, 2)}</span>
                  <span className='atAGlanceImpressionsTitle'>Since June 6, 2023</span>

              </div>
              <div className='atAGlanceTodayImpressions'>
                  <span className='atAGlanceTodayImpressionsTitle'>Impressions Today</span>
                  <span className='atAGlanceTodayImpressionsCount'>{abbreviate(impressionsToday, 2)}</span>
                  <div id="borderRight"></div>
              </div>
              <div className='atAGlanceTodayImpressions'>
                  <span className='atAGlanceTodayImpressionsTitle'>Total Budget Spent</span>
                  <span className='atAGlanceTodayImpressionsCount'>${abbreviate(allTimeBudget, 2)}</span>
                  <div id="borderRight"></div>
              </div>
              <div className='atAGlanceTodayImpressions'>
                  <span className='atAGlanceTodayImpressionsTitle'>Budget Spent Today</span>
                  <span className='atAGlanceTodayImpressionsCount'>${!isNaN(budgetToday) && abbreviate(budgetToday, 2)} {isNaN(budgetToday) && '0'}</span>
              </div>
          </div>
          <DashboardLineGraph></DashboardLineGraph>
          <DashboardCircleGraph></DashboardCircleGraph>
          <div className='topCampaignsContainer'>
              <span className='topCampaignsTitle'>My Campaigns</span>
              <span className='topCampaignsSubTitle' onClick={() => {
                  navigate("/advertiserDashboard/campaigns")
              }}>See All</span>
              <table className='topCampaignsTable'>
                  <thead >
                      <tr><th>Name</th>
                      <th>Impressions Remaining</th>
                          <th>Budget Remaining</th>
                      </tr>
                  </thead>
                  
                  <tbody>
                      {Array.isArray(topCampaigns) && topCampaigns.map((campaign,i) => (
                          <tr key={i}>
                              <td>{campaign.AdvertisementName}</td>
                              <td>{campaign.ImpressionsRemaining}</td>
                              <td>${abbreviate(campaign.CurrentBudget,2)}</td>
                          </tr>
                      ))}
                    
                  </tbody>
              </table>
</div>

      </div>
  )
}
