import React, { useState, useEffect } from 'react'
import './BusinessDetails.css';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { checkEmptyObj } from '../../../services/checkEmptyObj';
import {createBusiness} from '../../../services/createBusiness';
import { useSelector } from 'react-redux';
import { getToken } from '../../../features/token/tokenSaver';
import secureLocalStorage from 'react-secure-storage';
export default function BusinessDetails() {
    const [businessData, setBusinessData] = useState({bName: "", bAddress: "", bCity: "", bState:"", bZipCode:""});
    const [error, setError] = useState(false);
    const { state } = useLocation();
    const [validForm, setValidForm] = useState('disabled');
    let token = secureLocalStorage.getItem("tk");
    const navigate = useNavigate();
    useEffect(() => {
        if (!token) {
            token = secureLocalStorage.getItem("tk")
        }
        if (state) {
            if (!state.phoneNumber) {
                navigate('/')
            }
        }
        else {
            navigate('/')

        }
        return () => {

        }
    }, []);
    function onChange(propertyName, newValue) {
        setBusinessData({ ...businessData, [propertyName]: newValue });
        if (checkEmptyObj({ ...businessData, [propertyName]: newValue })) {
            setValidForm('disabled');

        }
        else {
            setValidForm('')

        }
    }
    function onSubmit(event) {
        event.preventDefault();
        createBusiness({ ...businessData, phoneNumber: state.phoneNumber }, token).then(response => {
            if (response.data.success) {
                navigate("/user/todo", { state: { phoneNumber: state.phoneNumber.replace('+1', '') } })

            }
            else {
                setError(true)
            }
        }).catch(e => {
            setError(true)

            console.error(e)
        })
    }
    return (
        <div>
            <div className='nav'>
                <div className='todoPageNavTitle'>INVERT</div>
            </div>
            <form onSubmit={onSubmit}>
            <div className='businessDetailsContainer'>
                <span className='businessDetailsTitle'>Business Information</span>
                <div className='businessInfoTopInputContainer'>

                <label className='labelForBusinessInput'>Business Name</label>

                    <input required maxLength={50} value={businessData.bName} onChange={(e) => { onChange("bName", e.nativeEvent.target.value) }} className='businessInfoNameInput' type="text" placeholder="Business Name" />
                </div>
                <div className='businessInfoTopInputContainer'>

                <label className='labelForBusinessInput'>Business Address</label>

                    <input required maxLength={50} value={businessData.bAddress} onChange={(e) => { onChange("bAddress", e.nativeEvent.target.value) }}  className='businessInfoNameInput' type="text" placeholder="Business Address" />
                </div>
                    <div className='businessInfoInputContainer'>
                    <label className='labelForBusinessInput'>Business City</label>
                    <input required value={businessData.bCity} onChange={(e) => { onChange("bCity", e.nativeEvent.target.value) }}  maxLength={50} className='businessInfoCityInput' type="text" placeholder="Business City" />
               </div>
                    <div className='businessInfoInputContainer middle'>
                    <label className='labelForBusinessInput'>Business State</label>
                    <input required maxLength={50} value={businessData.bState} onChange={(e) => { onChange("bState", e.nativeEvent.target.value) }}  className='businessInfoCityInput businessinputstate' type="text" placeholder="Business State" />
                </div>
                    <div className='businessInfoInputContainer last'>
                    <label className='labelForBusinessInput'>Business ZipCode</label>
                    <input required maxLength={50} value={businessData.bZipCode} onChange={(e) => { onChange("bZipCode", e.nativeEvent.target.value) }}  className='businessInfoCityInput' type="text" placeholder="Business ZipCode" />
                    </div>
                    <input type={"submit"} value={"Save"} className={`businessDetailsSave ${validForm}`}/>
                    {error && <span className='errorPhoneVerification'>There was an error submitting your details, please try again later.</span>}
                </div>

            </form>
        </div>
    )
}
