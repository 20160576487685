import React,{useEffect, useState} from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Label } from 'recharts';
import './DashboardCircleGraph.css';
import { getToken } from '../../../../../features/token/tokenSaver';
import { useSelector } from 'react-redux';
import { getImpressionRatio } from '../../../../../services/getImpressionRatio';
const DashboardCircleGraph = () => {
    const token = useSelector(getToken);
    const [data, setData] = useState([]);

    const COLORS = ['#CA3C0F', '#333333'];
    useEffect(() => {

        function parsed() {
            try {
                return JSON.parse(atob(token.split('.')[1]));
            } catch (e) {
                return null;
            }
        }
        const decoded = parsed();
        if (decoded) {

            getImpressionRatio(token, decoded.id).then(response => {
              
                const data = response.data;
                setData([
                    { name: 'Impressions During Desired Time', value: parseInt(data.atDesiredTime) },
                    { name: 'Not During Desired Time', value: parseInt(data.notAtDesiredTime) }]);
              
            });
        }
    }, [token])


    function CustomLabel() {
        const value = 'OF IMPRESSIONS AT\nDESIRED TIMES';
        return (
            <text x={0} y={0} dy={-10} fontSize="12" textAnchor="middle">
                {value.split('\n').map((val, index) => (
                    <tspan key={index} x={0} dy={index * 15}>
                        {val}
                    </tspan>
                ))}
            </text>
        )
    }
    return (<div className='circleGraphContainer'>
        {
            data.length > 0 &&    <ResponsiveContainer width={400}  height={400}>
       <PieChart className='gaugeChart' >
            <Pie
                data={data}
                cx={"50%"}
                cy={200}
                innerRadius={100}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={0}
                        dataKey="value"
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}

                    <Label fill='black' style={{ marginTop: 10 }} fontSize={40} value={`${(((parseInt(data[0].value) / parseInt(data[0].value + data[1].value))) * 100).toFixed(1)}%`} position="centerBottom" />
                        <Label width={200} position={"centerTop"} display={12} fontSize={15} >OF IMPRESSIONS AT DESIRED TIMES</Label>
            </Pie>
            </PieChart>
            
            </ResponsiveContainer>
        }
        {
        data.length == 0 &&
        <div></div>
        }
    </div>
    );
};

export default DashboardCircleGraph;