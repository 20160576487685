import React, { useState, useEffect } from 'react'
import './Verification.css';
import { useNavigate } from "react-router-dom";
import { sendTextMessage } from '../../../services/sendTextMessage';
import { registerUser } from '../../../services/registerUser';
import { useLocation } from 'react-router-dom';
import RingLoader from 'react-spinners/RingLoader';
import  secureLocalStorage  from  "react-secure-storage";
import { userLogin } from '../../../services/userLogin';
import { useDispatch } from 'react-redux';
import { save } from '../../../features/token/tokenSaver';

export default function Verification() {
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem("userData")));
    const [veriCode, setVeriCode] = useState("");
    const [resendDisabled, setResendDisabled] = useState(true);
    const [error, setError] = useState(false);
    const [timeLeftValue, setTimeLeftValue] = useState(60);
    const [code, setCode] = useState("");
    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()

    const navigate = useNavigate();

    
    useEffect(() => {
        clearInterval();
        var code = secureLocalStorage.getItem("code");
        if(code){
            setCode(code);
        }
    
        if (!state) {
            navigate("/");
        }
        else {
            secureLocalStorage.setItem("phoneNumber", state.user.phoneNumber.replace('+1', ''));
            setLoading(true);
            if (state.user.phoneNumber) {
                var ls = secureLocalStorage.getItem("ls");
                if (ls) {
                    setResendDisabled(true);
                    clearInterval();
                    setInterval(counter, 1000);
                    // if ((now - new Date(ls)) >= ONE_MIN) {
                    //     var vcode = "";
                    //     for (var i = 1; i <= 6; i++) {
                    //         vcode += Math.floor(Math.random() * 10).toString();
    
                    //     }
                    //     setUserData(state.user);
                    //     setCode(vcode);
                    //     console.log(vcode)
                    //     secureLocalStorage.setItem("code", vcode);
                    //     sendTextMessage(state.user.phoneNumber, vcode);
    
                    //     secureLocalStorage.setItem("ls", now);
                    // }
                  
                    // else{
                    //     var vcode = "";
                    //     for (var i = 1; i <= 6; i++) {
                    //         vcode += Math.floor(Math.random() * 10).toString();
    
                    //     }
                    //     setUserData(state.user);
                    //     setCode(vcode);
                    //     secureLocalStorage.setItem("code", vcode);
                    //     sendTextMessage(state.user.phoneNumber, vcode);
    
                    //     secureLocalStorage.setItem("ls", now);
                    // }
                    setLoading(false);
                  


                }
                else{
                    const now = new Date();
                        var vcode = "";
                        for (var i = 1; i <= 6; i++) {
                            vcode += Math.floor(Math.random() * 10).toString();
    
                        }
                        setUserData(state.user);
                        setCode(vcode);
                        secureLocalStorage.setItem("code", vcode);
                        sendTextMessage(state.user.phoneNumber, vcode);
    
                        secureLocalStorage.setItem("ls", now);
                        setLoading(false);
                    setResendDisabled(true);
                    clearInterval();
                    setInterval(counter, 1000);
                }
            }
            else {
                setLoading(false);

                navigate("/")
            }
        }
        document.getElementById('verificationInput').focus();
       

    }, []);
    function counter() {
        var now = new Date();
        var ls = secureLocalStorage.getItem("ls");
        var timeLeft = (60 - Math.ceil((now - new Date(ls)) / 1000));
        setTimeLeftValue(timeLeft);
        if (timeLeft <= 0) {
            setResendDisabled(false);
        }
    }
    function onChange(event) {
        setVeriCode(event.target.value);
    }
    function resendCode() {
        if (!resendDisabled) {
            const now = new Date();
            var vcode = "";
            for (var i = 1; i <= 6; i++) {
                vcode += Math.floor(Math.random() * 10).toString();

            }
            setUserData(state.user);
            setCode(vcode);
            secureLocalStorage.setItem("code", vcode);
            sendTextMessage(state.user.phoneNumber, vcode);

            secureLocalStorage.setItem("ls", now);
            setLoading(false);
            setResendDisabled(true);
            clearInterval();
            setInterval(counter, 1000);

        }
    }
    function onSubmit(event) {
        event.preventDefault();
        if (veriCode === code) {
            secureLocalStorage.setItem("ls", null);
            secureLocalStorage.setItem("code", null);
            
            setError(false);
            if (state.toRegister) {
                registerUser(userData)
                    .then(function (result) {
                        dispatch(save(result.data.token));
                        secureLocalStorage.setItem("tk", result.data.token);

                        localStorage.setItem("refreshToken", result.data.refreshToken);
                        navigate("/user/todo", { state: { phoneNumber: secureLocalStorage.getItem("phoneNumber"), token: result.data.token } })



                    })
                    .catch(function (error) {
                        setError(true);

                        console.log(error);
                    });
            }
            else {
                userLogin({ phoneNumber: state.user.phoneNumber, code: veriCode }).then(result => {
                    dispatch(save(result.data.token));
                    secureLocalStorage.setItem("tk", result.data.token);

                    secureLocalStorage.setItem("refreshToken", result.data.refreshToken);
                    navigate("/user/todo", { state: { phoneNumber: secureLocalStorage.getItem("phoneNumber"), token: result.data.token } })

                })

            }
        }
        else {
            setError(true);

        }
    }
    return (
        <div className='verificationPage'>
            <div className='verificationContainer'>
                <div className='verificationBox'>
                    <form onSubmit={onSubmit}>
                    <span className='verificationBoxLogo'>INVERT</span>
                    <span className='verificationBoxTitle'>Enter Verification Code</span>
                    <span className='verificationBoxSubTitle'>Verify your account by typing in the 6 digit code we sent via text.</span>
                    <input id='verificationInput' value={veriCode} onChange={onChange} required className='verificationInput' type="text" pattern="\d*" maxLength="6" minLength={6} placeholder="- - - - - -" />
                       
                      { error && <span className='errorPhoneVerification'>No matching phone number and code found</span>}

                      <button className="verificationBtn" type='submit' value={"Save"} >
                        {!loading && "Next"}
                        {loading && <RingLoader
                            color={"#ffffff"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                                data-testid="loader"
                                className='loader'
                        />}
                        </button>
                        <span onClick={()=>{resendCode()}} className={`resendButton ${resendDisabled && 'disabledResendLink'}`} >
                            Resend Code {resendDisabled && timeLeftValue > 0 && `(${timeLeftValue}sec)`}
                        </span>  </form>
                </div> 
            </div>

        </div>
    )
}
