import React, { useEffect, useState } from 'react'
import './AdminDashboardUsersDetails.css';
import screenProfile from '../../../../assets/images/screenProfile.png';
import leftChevron from '../../../../assets/images/leftChevron.png';
import { useLocation, useOutletContext } from 'react-router-dom';
import { getScreenDetails } from '../../../../services/getScreenDetails';
import { useSelector } from 'react-redux'
import { getToken } from '../../../../features/token/tokenSaver';
import { getApplicantData } from '../../../../services/getApplicantData';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { sendApplicationComment } from '../../../../services/sendApplicationComment';
import { reopenBusinessDetails } from '../../../../services/reopenBusinessDetails';
import { RingLoader } from 'react-spinners';
import { reopenDocumentUpload } from '../../../../services/reopenDocumentUpload';
import { reopenZelleDetails } from '../../../../services/reopenZelleDetails';
import { approveApplication } from '../../../../services/approveApplication';
import ReactJsAlert from "reactjs-alert"
import { putApplicationUnderReview } from '../../../../services/putUnderReview';
import { denyApplication } from '../../../../services/denyApplication';
import Select from 'react-select'
import { getScreenLocations } from '../../../../services/getScreenLocations';
import { getScreenLocationsAdmin } from '../../../../services/getScreenLocationAdmin';
import CreatableSelect from 'react-select/creatable';
import { updateUserLocation } from '../../../../services/updateUserLocation';
import { addLocation } from '../../../../services/addLocation';
export default function AdminDashboardUsersDetails(props) {
    const [userDetails, setUserDetails] = useState({});
    const [alertActive, setAlertActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [commentBody, setCommentBody] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successCode, setSuccessCode] = useState('');
    const [allLocationsCities, setAllLocationsCities] = useState([]);
    const [locationIds, setLocationIds] = useState([]);
    const [allLocations, setAllLocations] = useState([]);
    const [adLogs, setAdLogs] = useState([]);
    const [allLocationsStates, setAllLocationsStates] = useState([]);
    const [selectedLocationsCities, setSelectedLocationsCities] = useState(1);
    const [selectedLocationsStates, setSelectedLocationsStates] = useState(1);
    const [errorCode, setErrorCode] = useState(false);
    const [totalMade, setTotalMade] = useState({});
    const [totalUnpaidMade, setTotalUnpaidMade] = useState({});
    const [totalPayed, setTotalPaid] = useState({});
    var abbreviate = require('number-abbreviate')

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const token = useSelector(getToken);
    function updateLocations() {
        let hasMatch = false;
        let matchedLocationId;
        allLocations.forEach(location => {
            if (location.City == selectedLocationsCities?.label && location.State == selectedLocationsStates?.label) {
                hasMatch = true; 
                matchedLocationId = location.LocationId;
            }
        })

        if (hasMatch && selectedLocationsCities?.value && selectedLocationsStates?.value  && locationIds.includes(selectedLocationsStates?.value) && locationIds.includes(selectedLocationsCities?.value) ) {
            updateUserLocation(token, { userId: userDetails.UserId, locationId: matchedLocationId }).then(response => {
                setSuccess(true);
                setSuccessCode("Locations Updated");
                getApplicantData(token, props.screenId).then(response => {
                    setUserDetails(response.data.user);
                    getAllLocations(response.data.user);

                })
            }).catch(e => {
                setErrorCode("There was an error setting the location");
                setError(true);
            })
        }
        else if (!hasMatch && selectedLocationsCities?.value && selectedLocationsStates?.value && (!locationIds.includes(selectedLocationsStates?.value) || !locationIds.includes(selectedLocationsCities?.value) )) {
            addLocation(token, { city: selectedLocationsCities.label, state: selectedLocationsStates.label }).then(response => {
               
                updateUserLocation(token, { userId: userDetails.UserId, locationId: response.data.locationId }).then(response => {
                    setSuccess(true);
                    setSuccessCode("Locations Updated");
                    getApplicantData(token, props.screenId).then(response => {
                        setUserDetails(response.data.user);
                        getAllLocations(response.data.user);

                    })
                }).catch(e => {
                    setErrorCode("There was an error setting the location");
                    setError(true);
                })
            }).catch(e => {
                setErrorCode("There was an error setting the location");
                setError(true);
            })
        }
        else {
            setError(true);
            setErrorCode("Make Sure to Select a Location")
        }
    }
    function getAllLocations(userDetails) {
        getScreenLocationsAdmin(token).then(result => {
            let optionsCities = [];
            let optionsStates = [];
            let locationIds = [];
            let locationStates = [];
            let locationCities = [];
            setAllLocations(result.data.data);
            result.data.data.forEach(location => {
                if (!locationCities.includes(location.City)) {
                    optionsCities.push({ label: location.City, value: location.LocationId })
                    locationCities.push(location.City);

                } 
                if(!locationStates.includes(location.State)){
                optionsStates.push({ label: location.State, value: location.LocationId })
                    locationStates.push(location.State);

                } locationIds.push(location.LocationId);
            });
            setLocationIds(locationIds);

            setAllLocationsCities(optionsCities);
            setAllLocationsStates(optionsStates);
            if (userDetails?.LocationId[0]) {
                var locationId = userDetails.LocationId;
                if (Array.isArray(userDetails.LocationId)) {
                    locationId = locationId[0];
                }
                var location = result.data.data.find(obj => {
                    return obj.LocationId === locationId;
                })
                setSelectedLocationsStates({ label: location.State, value: location.LocationId })
                setSelectedLocationsCities({ label: location.City, value: location.LocationId})
            }
        }).catch(e => {
            setAllLocationsCities([]);
            setAllLocationsStates([]);
        })
    }
    function sendComment() {
        setLoading(true)
        sendApplicationComment(userDetails.Email, userDetails.FName, commentBody, token).then(response => {
            setAlertActive(false);
            setLoading(false)
            setSuccess(true);
            setSuccessCode("Comment Sent")

        }).catch(e => {
            setLoading(false)
            setSuccess(false)
            setError(true);
            setErrorCode("There was an error sending your comment, please try again")
        });
        setCommentBody('');
    }
    function reopenBusiness(userId) {
        setLoading(true)

        reopenBusinessDetails(userId, token).then(response => {
            setLoading(false)

            setSuccess(true);
            setSuccessCode("Reopened")
        }).catch(err => {
            setLoading(false)

            setSuccess(false)
            setError(true);
            setErrorCode("There was an error reopening, please try again later.")
        })
    }
    function reopenDocument(userId) {
        setLoading(true)

        reopenDocumentUpload(userId, token).then(response => {
            setLoading(false)

            setSuccess(true);
            setSuccessCode("Reopened")
        }).catch(err => {
            setLoading(false)

            setSuccess(false)
            setError(true);
            setErrorCode("There was an error reopening, please try again later.")        })
    } 
    function reopenZelle(userId) {
        setLoading(true)
        reopenZelleDetails(userId, token).then(response => {
            setLoading(false)

            setSuccess(true);
            setSuccessCode("Reopened")
        }).catch(err => {
            setLoading(false)

                setSuccess(false)
                setError(true);
                setErrorCode("There was an error reopening, please try again later.") 
        })
    }
    function approveApplicant(userId, fname, email) {
        setLoading(true)
        approveApplication(userId, token, fname, email).then(response => {
            setLoading(false)

            setSuccess(true);
            setSuccessCode("Application Approved!") 
        }).catch(e => {
            setLoading(false)

            setSuccess(false)
            setError(true);
            setErrorCode("There was an error approving, please try again later.") 
        })
    }
    function setApplicantUnderReview(userId, fname, email) {
        setLoading(true)
        putApplicationUnderReview(userId, token, fname, email).then(response => {
            setLoading(false)

            setSuccess(true);
            setSuccessCode("Application put under review.")
        }).catch(e => {
            setLoading(false)

            setSuccess(false)
            setError(true);
            setErrorCode("There was an error putting the application under review, please try again later.")
        })
    }
    function denyApplicant(userId, fname, email) {
        setLoading(true)
        denyApplication(userId, token, fname, email).then(response => {
            setLoading(false)

            setSuccess(true);
            setSuccessCode("Application denied.")
        }).catch(e => {
            setLoading(false)

            setSuccess(false)
            setError(true);
            setErrorCode("There was an error putting the denying the application, please try again later.")
        })
    }
    useEffect(() => {
        getApplicantData(token, props.screenId).then(response => {
            setUserDetails(response.data.user);
            getAllLocations(response.data.user);
            setAdLogs(response.data.adLogs);
            setTotalMade(response.data.totalMade);
            setTotalPaid(response.data.totalPayed);
            setTotalUnpaidMade(response.data.totalUnpaidMade);
        });

        return () => {
            
        }
    }, [])
  
    return (
        <div className='AdminUserDetailsContainer'>
            <ReactJsAlert
                status={error} // true or false
                type={"error"} // success, warning, error, info
                title={"There was an error submitting your form"}
                quotes={true}
                quote={errorCode}
                Close={() => setError(false)}
                color="#1E1E1E"

            />
            <ReactJsAlert
                status={success} // true or false
                type={"success"} // success, warning, error, info
                title={'Success!'}
                quotes={true}
                quote={successCode}
                Close={() => setSuccess(false)}
                color="#1E1E1E"

            />
            {alertActive && <div className='alert'>
                <span className='commentTItle'>Send A Comment to {userDetails.FName}</span>
                <textarea value={commentBody} onChange={(e)=>{setCommentBody(e.target.value)}} placeholder='Write your message here' maxLength={600} className='commentTextArea'>

                </textarea>
                <button disabled={commentBody.length <= 10} onClick={() => {sendComment()}} className={`cancelCommentBtn ${commentBody.length <= 10 && 'disabled'}`}>
                    {!loading && "Send"}
                    {loading && <RingLoader
                        color={"#ffffff"}
                        loading={true}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        className='loader'
                    />}
                </button>
                <input type={"button"} onClick={() => { setAlertActive(false) }} className='cancelCommentBtn' value='Cancel' />


            </div>
            }
            {(alertActive || success || error) && <div className='opaqueBackground '></div>}

            <img className='leftChevronScreenDetails'onClick={()=>{props.screensTable()}} src={leftChevron}/>
             <div className='screenDetailsScreenDetailsContainer userDetailsContainer'>

                <span className='screenDetailsScreenId'>#{userDetails.UserId}</span>
                
                    <p className='ApplicationStatus'> Application Status: {["Incomplete", "Approved", "Denied", "In Review", "Active"][userDetails.ApplicantStatusId - 1]}</p>
                    <Tabs>
                        <TabList>
                            <Tab>User Details</Tab>
                        <Tab>Business Details</Tab>
                        {userDetails.ApplicantStatusId === 2 && userDetails.DisplayLinkCommplete == 1 &&<Tab>Payments</Tab>}
                        </TabList>

                        <TabPanel>
                            <h3 className='tabTitle'>User Details</h3>
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>Email</span>
                                <span className='userDetailsData'>{userDetails.Email}</span>
                            </div>
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>First Name</span>
                                <span className='userDetailsData'>{userDetails.FName}</span>
                            </div>
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>Last Name</span>
                                <span className='userDetailsData'>{userDetails.LName}</span>
                            </div>
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>City</span>
                            <span className='userDetailsData'>{Array.isArray(userDetails.City) && userDetails.City[1] || userDetails.City}</span>
                            </div>
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>State</span>
                                <span className='userDetailsData'>{userDetails.State}</span>
                            </div>
     
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>Country</span>
                                <span className='userDetailsData'>{userDetails.Country}</span>
                            </div>
                            
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>Phone Number</span>
                                <span className='userDetailsData'>{userDetails.PhoneNumber}</span>
                            </div>
                        <div className='setUserLocationContainer'>
                            <span className='userDetailsTitle'>Set Screen City</span>
                            <CreatableSelect value={selectedLocationsCities} onChange={setSelectedLocationsCities} className="multiSelectNewCampaignInput" required isMulti={false} options={allLocationsCities} />
                           
                        </div>
                        <div className='setUserLocationContainer'>
                            <span className='userDetailsTitle'>Set Screen State</span>

                            <CreatableSelect value={selectedLocationsStates} onChange={setSelectedLocationsStates} className="multiSelectNewCampaignInput" required isMulti={false} options={allLocationsStates} />
                        </div>
                        <button onClick={() => { updateLocations() }} className='sendCommentBtn userDetailsBtn saveLocationsBtn'>Save Locations</button>

                        </TabPanel>
                        <TabPanel>
                            <h3 className='tabTitle'>Business Details</h3>
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>Business Details Complete</span>
                                <span className='userDetailsData'> {userDetails.BusinessDetailsComplete && 'True'}{!userDetails.BusinessDetailsComplete && 'False'}</span>
                            </div>
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>Business Name</span>
                                <span className='userDetailsData'>{userDetails.BusinessName}</span>
                            </div>
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>Business City</span>
                                <span className='userDetailsData'>{userDetails.BusinessCity}</span>
                            </div>
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>Business State</span>
                                <span className='userDetailsData'>{userDetails.BusinessState}</span>
                            </div>
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>Business Zip Code</span>
                                <span className='userDetailsData'>{userDetails.BusinessZipCode}</span>
                            </div>
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>Document Upload Complete</span>
                                <span className='userDetailsData'>{userDetails.DocumentUploadComplete && 'True'}{!userDetails.DocumentUploadComplete && 'False'}</span>
                            </div>
                            <div className='userDetailsItem documentsUserDetailsItem'>
                                <span className='userDetailsTitle'>Documents</span>
                                <span className='userDetailsData'> <a href={userDetails.TaxReturnSource}>Business Tax Return </a>
                                    <br /> <a href={userDetails.BusinessCOISource}>Business COI </a>
                                    <br /><a href={userDetails.StoreImageSource}>Business Store Image </a></span>
                            </div>
                           
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>Zelle Details Complete</span>
                                <span className='userDetailsData'> {userDetails.ZelleComplete && 'True'}{!userDetails.ZelleComplete && 'False'}</span>
                            </div>
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>Zelle Phone Number</span>
                                <span className='userDetailsData'> {userDetails.ZellePhoneNumber || 'None'}</span>
                            </div>
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>Zelle Email</span>
                                <span className='userDetailsData'> {userDetails.ZelleEmail || 'None'}</span>
                            </div>
                            <div className='userDetailsItem'>
                                <span className='userDetailsTitle'>Display Link Complete</span>
                                <span className='userDetailsData'> {userDetails.DisplayLinkComplete && 'True'}{!userDetails.DisplayLinkComplete && 'False'}</span>
                            </div>
                            <div className='userDetailsItem'>
                             
                            </div>
                            <button className='reopenBtn userDetailsBtn' onClick={() => {
                                reopenBusiness(userDetails.UserId);
                            }}>    {!loading && "Reopen Business Details"}
                                {loading && <RingLoader
                                    color={"#ffffff"}
                                    loading={true}
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                    className='loader'
                                />}</button>
                            <button onClick={() => {
                                reopenDocument(userDetails.UserId);
                            }} className='reopenBtn userDetailsBtn'>    {!loading && "Reopen Document Upload"}
                                {loading && <RingLoader
                                    color={"#ffffff"}
                                    loading={true}
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                    className='loader'
                                />}</button>
                            <button onClick={() => {
                                reopenZelle(userDetails.UserId);
                            }} className='zelleDetailsBtn userDetailsBtn'>    {!loading && "Reopen Zelle Details"}
                                {loading && <RingLoader
                                    color={"#ffffff"}
                                    loading={true}
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                    className='loader'
                                />}</button>
                        </TabPanel>
                    <TabPanel>
                        <h3 className='tabTitle'>Ad Log Details</h3>
                        <div className='userDetailsItem'>
                            <span className='userDetailsTitle'>Total Made:</span>
                            <span className='userDetailsData'>{formatter.format(totalMade.TotalCostSum)}</span>
                        </div>
                        <div className='userDetailsItem'>
                            <span className='userDetailsTitle'>Total Owed:</span>
                            <span className='userDetailsData'>{formatter.format(totalMade.TotalCostSum - totalPayed.TotalCostSum)}</span>
                        </div>
                        <div className='userDetailsItem'>
                            <span className='userDetailsTitle'>Payed: </span>
                            <span className='userDetailsData'>{formatter.format(totalPayed.TotalCostSum)}</span>
                        </div>
                        <div className='userDetailsItem'>
                            <span className='userDetailsTitle'>Unpaid: </span>
                            <span className='userDetailsData'>{formatter.format(totalUnpaidMade.TotalCostSum)}</span>
                        </div>
                        <div className='userDetailsItem'>
                            <span className='userDetailsTitle'>Total Impressions: </span>
                            <span className='userDetailsData'>{abbreviate(adLogs.length)}</span>
                        </div>
                            {adLogs && <table className='AdminDashboardUsersTable'>
                                <thead>
                                    <tr>
                                        <th>Log Id</th>
                                        <th>Ad Id</th>
                                        <th>Screen Id</th>
                                        <th>Payed</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {adLogs.map(adLog => {
                                        return <tr key={adLog.AdLogId} >
                                            <td>{adLog.AdLogId}</td>
                                            <td>{adLog.AdId}</td>
                                            <td>{adLog.ScreenId}</td>
                                            <td>{adLog.Payed && 'true' || 'false'}</td>

                                        </tr>
                                    })}
                                </tbody>
                            </table>}
                          
             
                        </TabPanel>
                    </Tabs>
                  
                    
               
                    <h4>Ready For Screen Approval Review: {userDetails.BusinessDetailsComplete && userDetails.DocumentUploadComplete && userDetails.ZelleComplete && "Yes" || "No"}</h4>
                 
                    <button onClick={()=>{setAlertActive(true)}} className='sendCommentBtn userDetailsBtn'>Send Comment</button>
                    <button onClick={() => { approveApplicant(userDetails.UserId, userDetails.FName, userDetails.Email) }} className='approveBtn userDetailsBtn'> {!loading && "Approve Applicant"}
                        {loading && <RingLoader
                            color={"#ffffff"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                            className='loader'
                        />}</button>
                    <button onClick={() => { setApplicantUnderReview(userDetails.UserId, userDetails.FName, userDetails.Email)}} className='approveBtn userDetailsBtn placeUnderReviewBtn'>{!loading && "Place Applicant Under Review"}
                        {loading && <RingLoader
                            color={"#ffffff"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                            className='loader'
                        />}</button>
                    <button onClick={() => { denyApplicant(userDetails.UserId, userDetails.FName, userDetails.Email) }} className='denyBtn userDetailsBtn'> {!loading && "Deny Applicant"}
                        {loading && <RingLoader
                            color={"#D23F3F"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                            className='loader'
                        />}</button>                    
                </div>
            </div>

    )
}
